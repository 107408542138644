// stores/counter.js
import {defineStore} from 'pinia'

export const sportStore = defineStore('sportStore', {
    state: () => {
        return {
            items: [
                {
                    label: 'Football',
                    code: 'FOOTBALL',
                    icon: 'fa-solid fa-futbol',
                },
                {
                    label: 'Hockey',
                    code: 'HOCKEY',
                    icon: 'fa-solid fa-hockey-puck',
                },
                {
                    label: 'Tennis',
                    code: 'TENNIS',
                    icon: 'fa-solid fa-baseball',
                },
            ]
        }
    },
    actions: {},
    getters: {

        get: (state) => (code) => {
            let find = state.items.find(item => item.code === code);
            return find
        },

        getAll: (state) => {
            return state.items
        },

        getIcons: (state) => (code) => {
            let find = state.items.find(item => item.code === code);
            if (!find) {
                return 'fa-solid fa-question'
            }
            return find.icon
        },
        getPointName: (state) => (code) => {
            switch (code) {
                case 'FOOTBALL':
                    return 'Buts'
                case 'BASKETBALL':
                    return 'Points'
                case 'HANDBALL':
                    return 'Buts'
                case 'RUGBY':
                    return 'Points'
                case 'HOCKEY':
                    return 'Buts'
                case 'TENNIS':
                    return 'Jeux'
            }
        }
    }
})
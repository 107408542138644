<script>

import {selectionStore} from "@/stores/Selection.store";
import {selectionPaneStore} from "@/components/routes/selection/panes/selection/SelectionPane.store";
import {contextStore} from "@/stores/Context.store";

export default {
  name: "OddProfilingMatch",
  components: {},
  props: {
    operandUid: {
      required: true
    },
    value: {
      required: true,
    },
    gameCount: {
      required: true,
    }
  },

  computed: {

    _selectionActive() {
      let selectedSelectionUID = selectionPaneStore().getSelectionActiveUIDBySport(contextStore().getSport);
      return selectionStore().get(selectedSelectionUID);
    },
    _operand() {
      const allOperands = selectionStore().getAllOperands(this._selectionActive.operation)
      return allOperands.find(o => o.uid === parseInt(this.operandUid))
    },
    _value() {
      if(!this.value) return '0.0%'
      return (this.value * 100.0).toFixed(1) + '%'
    },
    _label() {
      if(!this._operand) return '?'
      return selectionStore().getOperandLabel(this._operand, {
        'selection' : this._selectionActive
      })
    },
    _buildCssClasses() {
      return {
        'selection-match flex': true,
        'positive': true
      }
    }
  }
}
</script>

<template>
  <div :class="_buildCssClasses" v-if="_operand">
    <div class="value pl-2">
      {{_value}}
    </div>
    <div class="value pl-2">
      ({{gameCount}})
    </div>
    <div class="label ml-2 text-left py-1">
      {{ _label }}
    </div>
  </div>
  <div v-else>
    Loading...
  </div>
</template>

<style scoped lang="less">
@import "@/assets/styles/base.less";

.selection-match {
  color: white;
  font-size: @font-size-small;
  min-height: 24px;
  line-height: 24px;
  align-items: center;

  &:hover {
    background: @hover-color;
  }

  &.positive {
    background: @positive-color;
    background: linear-gradient(131deg, fade(@positive-color, 60%) 0%, fade(@positive-color, 30%) 20%, rgba(0,0,0,0) 33%);

    .value{
      font-weight: bold;
    }

  }

  &.negative {
    background: @negative-color;
    background: linear-gradient(131deg, fade(@negative-color, 60%) 0%, fade(@negative-color, 30%) 20%, rgba(0,0,0,0) 33%);
  }


  .value {
    font-weight: bold;
    text-align: right;
    min-width: 32px;
    display: flex;
    align-items: center;
  }
}
</style>
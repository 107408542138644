<script>
import {modalStore} from "@/stores/Modal.store";
import {contextStore} from "@/stores/Context.store";
import {userStore} from "@/stores/User.store";

export default {
  name: "SignInModal",
  computed: {
    _isVisible() {
      let visibleModal = modalStore().getVisibleModal;
      if (!visibleModal) return false;
      return visibleModal.uid === 'signInModal';
    },
    _isMobile() {
      return contextStore().isMobileDevice()
    },
  },
  methods: {
    _onCancel() {
      modalStore().closeModal();
    },

    _signInWithGoogle() {
      userStore().signInProvider('google')
    },

    _signInWithApple() {
      userStore().signInProvider('apple')
    },

    _goToPolicy() {
      this.$router.push({name: 'privacy'})
      this._onCancel()
    }
  }
}
</script>

<template>
  <AModal v-if="_isVisible" :open="true" :width="340" @cancel="_onCancel" :class="{'mobile': _isMobile}">
    <header class="ant-modal-header">
      Connexion
    </header>

    <div class="content pt-2 text-center">
      <h1 class="m-2">Plutôt team Google ou Apple ?</h1>
      <p class="mx-3 mt-2 text-justify">DoctoBET s'engage à protéger vos données et votre vie privée. C'est pourquoi nous utilisons les services d'authentification de Google et Apple pour sécuriser votre compte.</p>
      <div class="signin-buttons m-2">
        <button class="signin-button google" @click="_signInWithGoogle">
          <font-awesome-icon icon="fa-brands fa-google" class="provider-icon mr-2"/>
          <span>Connexion avec Google</span>
        </button>

        <button class="signin-button apple" @click="_signInWithApple">
          <font-awesome-icon icon="fa-brands fa-apple" class="provider-icon mr-2"/>
          <span>Connexion avec Apple</span>
        </button>
        <a-alert message="La connexion avec Apple est nouvelle. Contactez moi en cas de problème." type="info" show-icon/>
      </div>
    </div>

    <template #footer>
      <div class="flex p-2">
        <div class="flex-grow"/>
        <button class="info" @click="_goToPolicy">Politique de confidentialité</button>
      </div>
    </template>

  </AModal>
</template>

<style scoped lang="less">
.signin-buttons {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 20px;
}

.signin-button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 500;
  padding: 0 16px;
  cursor: pointer;
  transition: all 0.2s ease;
  border: 1px solid #ddd;
  width: 100%;

  .provider-icon {
    width: 24px;
    height: 24px;
    margin-right: 12px;
  }

  &.google {
    background-color: white;
    color: #444 !important;

    &:hover {
      background-color: #f5f5f5;
    }
  }

  &.apple {
    background-color: black;
    color: white;

    &:hover {
      background-color: #333;
    }
  }
}

</style>
// stores/counter.js
import { defineStore } from 'pinia'
import { selectionStore } from "@/stores/Selection.store";
import axiosService from "@/services/axios.service";
import { bookmakerStore } from "@/stores/Bookmaker.store";
import { preferencesStore } from "@/stores/Preferences.store";
import { leagueStore } from "@/stores/League.store";
import { offerStore } from "@/stores/Offer.store";
import { statStore } from "@/stores/Statistic.store";
import { perspectiveStore } from "@/stores/Perspective.store";
import { widgetDefinitionsStore } from "@/stores/WidgetDefinitions.store";
import { bankrollStore } from "./Bankroll.store";
import { monitoringStore } from "@/stores/Monitoring.store";
import { leagueGroupsStore } from "@/stores/LeagueGroups.store";
import { adsStore } from "@/stores/ads.store";
import { notebookStore } from "@/stores/NotebookStore";
import { statsRouteStore } from "@/components/routes/stats/StatsRoute.store";
import { selectionRouteStore } from "@/components/routes/selection/SelectionRoute.store";
import { settingStore } from "@/stores/Setting.store";
import { imageStore } from "@/stores/Image.store";
import indexedDBService from "@/services/indexedDB.service";
import { teamStore } from "@/stores/Team.store";
import { flagStore } from "@/stores/Flag.store";
import { liveStore } from "@/stores/Live.store";

export const contextStore = defineStore('contextStore', {
  state: () => {
    return {
      toInitialize: [
        'settings',
        'user', 'user-preferences',
        'statistic-definitions',
        'offer-families-definitions',
        'selections', 'flags', 'areas',
        'bookmakers', 'leagues', 'offer-families',
        'perspectives', 'widgets-definitions',
        'user-bankrolls', 'user-monitoring',
        'league-groups', 'live-status',
        'ads'
      ],
      toInitSize: null,

      // Context
      route: null,
      sport: null,
      loggedUser: null,
      gameUID: null,
      playerUID: null,
      bookmakerUID: null,
    }
  },
  actions: {
    shouldReload() {
      // On vérifie si on a un code parrainage dans l'url
      let shouldReload = false
      const urlParams = new URLSearchParams(window.location.search);
      const codeParrainage = urlParams.get('p');
      if (codeParrainage && codeParrainage !== '') {

        // On récupère le code parrainage actuel dans le local storage
        const currentCodeParrainage = localStorage.getItem('codeParrainage');
        if (!currentCodeParrainage || currentCodeParrainage !== codeParrainage) {
          // On met a jour le code parrainage
          localStorage.setItem('codeParrainage', codeParrainage);
          // On comptabilise le click du code
          axiosService.post('/usr/code-parrain?code=' + codeParrainage).then(() => {
            console.info('Code parrainage comptabilisé')
          })
        }
        shouldReload = true
      }
      // On verifie si un token est présent dans l'url
      const token = urlParams.get('token');
      if (token && token !== '') {
        localStorage.setItem('token', token);
        shouldReload = true
      }
      return shouldReload
    },
    initialize() {
      if (this.initialized) return
      console.log('contextStore.initialize')
      this.toInitSize = this.toInitialize.length
      this.initUser()
      this.initBookMedical()
      this.initTeams()
      this.initImages()
    },
    removeValueFromToInitialize(value) {
      this.toInitialize = this.toInitialize.filter(item => item !== value)
    },

    setRoute(route) {
      this.route = route
    },
    initBookMedical() {
      notebookStore().init()
    },
    initTeams() {
      indexedDBService.init().then(() => {
        console.log("indexedDBService.initialized")
        teamStore().init()
      })
    },
    initImages() {
      indexedDBService.init().then(() => {
        console.log("indexedDBService.initialized")
        imageStore().init()
      })
    },
    initUser() {
      console.log('contextStore.initUser')
      let token = localStorage.getItem('token');
      if (!token) {
        this.removeValueFromToInitialize('user')
        this.initSettings()
        return
      }

      // get code parrainage from local storage
      let codeParrainage = localStorage.getItem('codeParrainage');

      // check if token is valid
      let url = '/usr/me';
      if (codeParrainage) {
        url = '/usr/me?p=' + codeParrainage;
      }
      axiosService.get(url).then(response => {
        this.loggedUser = response.data.results[0]
        if (this.loggedUser.uid < 0) {
          localStorage.removeItem('token');
          this.loggedUser = null
        }
      }).catch(error => {
        console.error(error)
        //localStorage.removeItem('token');
      }).finally(() => {
        this.removeValueFromToInitialize('user')
        this.initSettings()
      });

    },
    initSettings() {
      console.log('contextStore.initSettings')
      settingStore().fetchActive(() => {
        this.removeValueFromToInitialize('settings')
        this.initUserPreferences()
      })
    },
    initUserPreferences() {
      console.log('contextStore.initUserPreferences')
      preferencesStore().fetchAll(() => {
        this.removeValueFromToInitialize('user-preferences')
        this.initStatisticDefinitions()
      })
    },
    initStatisticDefinitions() {
      console.log('contextStore.initStatisticDefinitions')
      statStore().fetchDefinitions(() => {
        this.removeValueFromToInitialize('statistic-definitions')
        this.initOfferFamiliesDefinitions()
      })
    },
    initOfferFamiliesDefinitions() {
      console.log('contextStore.initOfferFamiliesDefinitions')
      offerStore().fetchOfferFamiliesDefinitions(() => {
        this.removeValueFromToInitialize('offer-families-definitions')
        this.initSelections()
      })
    },
    initSelections() {
      console.log('contextStore.initSelections')
      selectionStore().fetchAll(() => {
        this.removeValueFromToInitialize('selections')
        this.initFlags()
      })
    },
    initFlags() {
      console.log('contextStore.initFlags')
      flagStore().fetchAll(() => {
        this.removeValueFromToInitialize('flags')
        this.initAreas()
      })
    },
    initAreas() {
      console.log('contextStore.initAreas')
      flagStore().fetchAll(() => {
        this.removeValueFromToInitialize('areas')
        this.initBookmakers()
      })
    },
    initBookmakers() {
      console.log('contextStore.initBookmakers')
      bookmakerStore().fetchAll(() => {
        this.removeValueFromToInitialize('bookmakers')
        this.initLeagues()
      })
    },
    initLeagues() {
      console.log('contextStore.initLeagues')
      leagueStore().fetchAll(() => {
        this.removeValueFromToInitialize('leagues')
        this.initOfferFamilies()
      })
    },
    initOfferFamilies() {
      console.log('contextStore.initOfferFamilies')
      offerStore().fetchFamilies(() => {
        this.removeValueFromToInitialize('offer-families')
        this.initPerspectives()
      })
    },
    initPerspectives() {
      console.log('contextStore.initPerspectives')
      perspectiveStore().fetchAll(() => {
        this.removeValueFromToInitialize('perspectives')
        this.initWidgetsDefinitions()
      })
    },
    initWidgetsDefinitions() {
      console.log('contextStore.initWidgetsDefinitions')
      widgetDefinitionsStore().fetchAll(() => {
        this.removeValueFromToInitialize('widgets-definitions')
        this.initUserBankrolls()
      })
    },
    initUserBankrolls() {
      console.log('contextStore.initUserBankrolls')
      bankrollStore().fetchAll(() => {
        this.removeValueFromToInitialize('user-bankrolls')
        this.initUserMonitoring()
      })
    },
    initUserMonitoring() {
      console.log('contextStore.initUserMonitoring')
      monitoringStore().fetchAll(() => {
        this.removeValueFromToInitialize('user-monitoring')
        this.initLeagueGroups()
      })
    },
    initLeagueGroups() {
      console.log('contextStore.initLeagueGroups')
      leagueGroupsStore().fetchAll(() => {
        this.removeValueFromToInitialize('league-groups')
        this.initLiveStatus()
      })
    },
    initLiveStatus() {
      console.log('contextStore.initLiveStatus')
      liveStore().fetchAll(() => {
        this.removeValueFromToInitialize('live-status')
        this.initAds()
      })
    },
    initAds() {
      console.log('contextStore.initAds')
      adsStore().fetchAll(() => {
        this.removeValueFromToInitialize('ads')
      })
    },
    logout() {
      localStorage.removeItem('token');
      this.loggedUser = null
      window.location.reload()
    },
    setSport(sport) {
      if (!contextStore().isLicensed()) {
        sport = 'FOOTBALL'  // On force le sport à FOOTBALL si l'utilisateur n'est pas licencié
      }

      if (this.sport === sport) return

      this.sport = sport
      window.emitter.emit('context-store:sport:change', sport)

      statsRouteStore().onSportChange()
      selectionRouteStore().onSportChange()
    },
    setPlayerUID(gameUID) {
      if (this.playerUID === playerUID) return

      this.playerUID = parseInt(playerUID)
      window.emitter.emit('context-store:player-uid:change', playerUID)
    },
    setGameUID(gameUID) {
      if (this.gameUID === gameUID) return

      this.gameUID = parseInt(gameUID)
      window.emitter.emit('context-store:game-uid:change', gameUID)
    },
    setBookmakerUID(bookmakerUID) {
      if (this.bookmakerUid === bookmakerUID) return
      this.bookmakerUid = parseInt(bookmakerUID)
      window.emitter.emit('context-store:bookmaker-uid:change', bookmakerUID)
    }
  },
  getters: {
    getScreenWidth: (state) => () => {
      return window.innerWidth
    },
    isMobileDevice: (state) => () => {
      return window.innerWidth < 1024
    },
    isInitialized: (state) => () => {
      return state.toInitialize.length === 0
    },
    isAuthenticated: (state) => () => {
      return state.loggedUser !== null
    },
    getLoggedUserUID: (state) => () => {
      if (!state.loggedUser) return null
      return state.loggedUser.uid
    },
    getInitiationProgress: (state) => () => {
      if (state.toInitialize.length === 0) return 100
      return 100 - (state.toInitialize.length / state.toInitSize) * 100
    },
    getSelectedPlayerUID: (state) => () => {
      return state.playerUID
    },
    getSelectedGameUID: (state) => () => {
      return state.gameUID
    },
    getSelectedBookmakerUID: (state) => () => {
      return state.bookmakerUID
    },
    getSport: (state) => {
      if (!state.sport) {
        // On va chercher dans les preferences utilisateur
        const ps = preferencesStore()
        let sport = ps.getFirstValue('SPORT', 'FAVORITE_SPORT');
        if (sport) {
          state.sport = sport
        } else {
          state.sport = 'FOOTBALL'
        }
      }
      return state.sport
    },

    isLicensed: (state) => () => {
      if (!state.loggedUser) return false
      if (!state.loggedUser.licenceExpirationDate) return false
      if (state.loggedUser.licenceType === 'NONE') return false
      return new Date(state.loggedUser.licenceExpirationDate) > new Date()
    },
    getLicenseType: (state) => () => {
      if (!contextStore().isLicensed()) return 'NONE'
      if (!state.loggedUser) return null
      return state.loggedUser.licenceType
    },
    hasFullLicence: (state) => () => {
      const licenceType = contextStore().getLicenseType()
      return licenceType === 'FULL'
    },
    getRoute: (state) => () => {
      return state.route
    }
  }
})
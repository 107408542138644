<script>
import axiosService from "@/services/axios.service";
import {gameStore} from "@/stores/Game.store";
import TeamLogo from "@/components/common/TeamLogo.vue";
import {liveStore} from "@/stores/Live.store";

export default {
  name: "SelectionPaneItem.Score.Tab",
  components: {TeamLogo},

  props: {
    gameUid: {
      required: true
    }
  },

  data() {
    return {
      data: null,
      liveMode: true, // Mode live activé par défaut

      selectedScores: [],

      isSettingsOpened: false
    }
  },

  beforeMount() {
    const payload = {
      uid: this.gameUid,
    }
    axiosService.put('/dta/game/basic-data/score', payload).then(response => {
      this.data = response.data
    })
  },

  computed: {
    _game() {
      return gameStore().get(this.gameUid)
    },

    _homeTeamUID() {
      if (!this._game)
        return null

      return this._game.homeTeamUID
    },

    _awayTeamUID() {
      if (!this._game)
        return null

      return this._game.awayTeamUID
    },

    _dataValid() {
      return this.data && this.data.homeTeamExpectedGoals && this.data.homeTeamExpectedGoals
    },

    _goals() {
      if (!this._game) return []
      const goals = []
      for (let i = this._minGoals; i <= this._maxGoals; i++) {
        goals.push(i)
      }
      return goals
    },

    // Retourne le top 3 des scores les plus probables
    _topScores() {
      if (!this.data) return []
      const results = []

      this._goals.forEach((h) => {
        this._goals.forEach((a) => {
          let proba = this._getProbaScore(h, a)
          if (proba > 0) {
            results.push({
              value: `${h}-${a}`,
              proba: proba
            })
          }
        })
      })
      return results.sort((a, b) => b.proba - a.proba).slice(0, 3)
    },

    _homeXginitial() {
      if (!this.data) return 0.01
      if (!this.data.homeTeamExpectedGoals) return 0.01

      let value = parseFloat(this.data.homeTeamExpectedGoals);
      if (value < 0.01) {
        return 0.01
      }
      return value
    },

    _homeXgCurrent() {
      let xgInitial = this._homeXginitial
      if (!xgInitial) return null

      if (!this._isLiveMode) {
        return xgInitial
      }

      if (!this._game) return xgInitial

      const currentGoal = this._liveStatus.homeScore || 0
      return currentGoal + ((xgInitial * this._homeResteProbaInitial) * (1 - this._progress))
    },

    _homeXgFormatted() {
      if (!this._homeXgCurrent) return '0.00'
      return parseFloat(this._homeXgCurrent).toFixed(2)
    },

    _awayXgInitial() {
      if (!this.data) return 0.01
      if (!this.data.awayTeamExpectedGoals) return 0.01

      let value = parseFloat(this.data.awayTeamExpectedGoals);
      if (value < 0.01) {
        return 0.01
      }
      return value
    },

    _awayXgCurrent() {
      let xgInitial = this._awayXgInitial
      if (!xgInitial) return null

      if (!this._isLiveMode) {
        return xgInitial
      }

      if (!this._game) return xgInitial

      const currentGoal = this._liveStatus.awayScore || 0
      return currentGoal + ((xgInitial * this._awayResteProbaInitial) * (1 - this._progress))
    },

    _awayXgFormatted() {
      if (!this._awayXgCurrent) return '0.00'
      return parseFloat(this._awayXgCurrent).toFixed(2)
    },

    _progress() {
      if (!this._game) return 0
      if (!this._isLiveMode) return 0

      const time = this._liveStatus.time || 0
      const extraTime = this._liveStatus.extraTime || 0
      return (time + extraTime) / 95.0 // 95 minutes de jeu dans un match
    },

    _minGoals() {
      if (!this._isLiveMode) {
        return 0
      }

      return Math.min(this._liveStatus.homeScore ?? 0, this._liveStatus.awayScore ?? 0)
    },

    _maxGoals() {
      if (!this._game) return this._minGoals + 5
      if (this._game.sport === 'HOCKEY') {
        return this._minGoals + 7
      }
      return this._minGoals + 5
    },

    _liveStatus() {
      if (!this._game) return null
      return liveStore().getBySportAndUID(this._game.sport, this._game.uid)
    },

    _isLiveMode() {
      return this.liveMode && this._isLiveAvailable
    },

    _isLiveAvailable() {
      if (!this._liveStatus) return false
      return this._liveStatus
    },

    _homeResteProbaInitial() {
      let homeMinScore = 0
      if (this._isLiveMode) {
        homeMinScore = this._liveStatus.homeScore ?? 0
      }

      let homeSum = 0
      for (let nbGoals = 0; nbGoals < homeMinScore; nbGoals++) {
        let nbGoalsProba = (Math.pow(Math.E, -this._homeXginitial) *
                Math.pow(this._homeXginitial, nbGoals)) /
            this._factorial(nbGoals);
        homeSum += nbGoalsProba;
      }
      return 1 - homeSum
    },

    _awayResteProbaInitial() {
      let awayMinScore = 0
      if (this._isLiveMode) {
        awayMinScore = this._liveStatus.awayScore ?? 0
      }

      let awaySum = 0
      for (let nbGoals = 0; nbGoals < awayMinScore; nbGoals++) {
        awaySum += (Math.pow(Math.E, -this._awayXgInitial) *
                Math.pow(this._awayXgInitial, nbGoals)) /
            this._factorial(nbGoals);
      }
      return 1 - awaySum
    },

    _probaMap() {
      if (!this._homeXginitial || !this._awayXgInitial) {
        return {homeProbaMap: {}, awayProbaMap: {}};
      }

      const homeProbaMap = {};
      const awayProbaMap = {};

      // Pour chaque nombre de buts possible sans prendre en compte les buts déjà marqués
      for (let nbGoals = 0; nbGoals <= this._maxGoals; nbGoals++) {
        // Calcul de la loi de Poisson pour l'équipe à domicile
        homeProbaMap[nbGoals] = (Math.pow(Math.E, -this._homeXgCurrent) *
                Math.pow(this._homeXgCurrent, nbGoals)) /
            this._factorial(nbGoals);

        // Calcul de la loi de Poisson pour l'équipe à l'extérieur
        awayProbaMap[nbGoals] = (Math.pow(Math.E, -this._awayXgCurrent) *
                Math.pow(this._awayXgCurrent, nbGoals)) /
            this._factorial(nbGoals);
      }

      if (!this.liveMode) {
        return {homeProbaMap, awayProbaMap};
      }

      let homeMinScore = 0
      let awayMinScore = 0
      if (this._isLiveMode) {
        homeMinScore = this._liveStatus.homeScore ?? 0
        awayMinScore = this._liveStatus.awayScore ?? 0
      }

      // On fait la somme des probabilités correspondant aux buts déjà marqués
      let homeObsoleteProba = 0
      let awayObsoleteProba = 0
      for (let nbGoals = 0; nbGoals < homeMinScore; nbGoals++) {
          homeObsoleteProba += homeProbaMap[nbGoals]
      }
      for (let nbGoals = 0; nbGoals < awayMinScore; nbGoals++) {
          awayObsoleteProba += awayProbaMap[nbGoals]
      }

      // On reajuste les probabilités pour supprimer les buts déjà marqués
      for (let nbGoals = 0; nbGoals <= this._maxGoals; nbGoals++) {
        if (nbGoals < homeMinScore) {
          homeProbaMap[nbGoals] = 0
        } else {
          homeProbaMap[nbGoals] = homeProbaMap[nbGoals] / (1 - homeObsoleteProba)
        }

        if (nbGoals < awayMinScore) {
          awayProbaMap[nbGoals] = 0
        } else {
          awayProbaMap[nbGoals] = awayProbaMap[nbGoals] / (1 - awayObsoleteProba)
        }
      }

      // Prise en compte du temps restant, plus on avance dans le match, plus les probabilités de marquer des buts baisse
      for (let nbGoals = 0; nbGoals <= this._maxGoals; nbGoals++) {
        let homeGoalsDiff = nbGoals - homeMinScore
        let homeCoeff = Math.exp(-this._progress * (homeGoalsDiff * 2))  // Permet de faire baisser les probabilités de marquer des buts
        homeProbaMap[nbGoals] = homeProbaMap[nbGoals] * homeCoeff

        let awayGoalsDiff = nbGoals - awayMinScore
        let awayCoeff = Math.exp(-this._progress * (awayGoalsDiff * 2))  // Permet de faire baisser les probabilités de marquer des buts
        awayProbaMap[nbGoals] = awayProbaMap[nbGoals] * awayCoeff
      }

      // On renormalise les probabilités
      let homeSum = 0
      let awaySum = 0
      for (let nbGoals = 0; nbGoals <= this._maxGoals; nbGoals++) {
        homeSum += homeProbaMap[nbGoals]
        awaySum += awayProbaMap[nbGoals]
      }
      for (let nbGoals = 0; nbGoals <= this._maxGoals; nbGoals++) {
        homeProbaMap[nbGoals] = homeProbaMap[nbGoals] / homeSum
        awayProbaMap[nbGoals] = awayProbaMap[nbGoals] / awaySum
      }

      // Si une proba est à 100%, on la met à 99.9% pour éviter les problèmes d'affichage
      for (let nbGoals = 0; nbGoals <= this._maxGoals; nbGoals++) {
        if (homeProbaMap[nbGoals] > 0.99) {
          homeProbaMap[nbGoals] = 0.990
        }
        if (awayProbaMap[nbGoals] > 0.99) {
          awayProbaMap[nbGoals] = 0.990
        }
      }

      return {homeProbaMap, awayProbaMap};
    }
  },

  methods: {

    // Fonction factorielle
    _factorial(n) {
      if (n === 0) return 1;
      return n * this._factorial(n - 1);
    },

    _onSelectScore(score) {
      if (this.selectedScores.includes(score)) {
        this.selectedScores = this.selectedScores.filter(s => s !== score)
      } else {
        this.selectedScores.push(score)
      }
    },

    _getProba(side, nbGoal) {
      if (!this._probaMap) return 0
      if (!this._probaMap[side + 'ProbaMap']) return 0
      if (!this._probaMap[side + 'ProbaMap'][nbGoal]) return 0
      let percent = this._probaMap[side + 'ProbaMap'][nbGoal];
      if (!percent)
        return 0
      return parseFloat((percent * 100.0).toFixed(0))
    },
    _getProbaScore(home, away) {
      if (!this._probaMap) return 0
      if (!this._probaMap.homeProbaMap) return 0
      if (!this._probaMap.homeProbaMap[home]) return 0
      if (!this._probaMap.awayProbaMap) return 0
      if (!this._probaMap.awayProbaMap[away]) return 0
      let percent = this._probaMap.homeProbaMap[home] * this._probaMap.awayProbaMap[away];
      if (!percent)
        return 0

      return parseFloat((percent * 100.0).toFixed(1))
    },
    getHighestProba(side) {
      if (!this._probaMap) return 0
      if (!this._probaMap.homeProbaMap) return 0
      let value = 0;
      Object.keys(this._probaMap.homeProbaMap).forEach((key) => {
        let p = this._getProba(side, key);
        if (p > value) {
          value = p
        }
      })
      return value
    },
    getHighestProbaScore() {
      if (!this._probaMap) return 0
      if (!this._probaMap.homeProbaMap) return 0
      let value = 0;
      Object.keys(this._probaMap.homeProbaMap).forEach((k1) => {
        Object.keys(this._probaMap.awayProbaMap).forEach((k2) => {
          let p = this._getProbaScore(k1, k2);
          if (p > value) {
            value = p
          }
        })
      })
      return value
    },
    getProbaColorTransparency(proba, side) {
      let opacity = proba / this.getHighestProba(side);
      opacity = Math.pow(opacity, 2)
      return `rgba(0, 128, 0, ${opacity})`
    },
    getProbaScoreColorTransparency(proba) {
      let opacity = proba / this.getHighestProbaScore();
      opacity = Math.pow(opacity, 2)
      return `rgba(0, 128, 0, ${opacity})`
    },

    _switchSettings() {
      this.isSettingsOpened = !this.isSettingsOpened
    },

    _switchLiveMode() {
      this.liveMode = !this.liveMode
    }
  }
}
</script>

<template>
  <div>
    <div style="position: absolute; top: 4px; right: 8px; z-index: 100">
      <button :class="{'small mr-1': true, 'secondary' : _isLiveMode, 'info': !_isLiveMode}" @click="_switchLiveMode" v-if="_isLiveAvailable">
        <font-awesome-icon icon="fa-solid fa-satellite-dish"/>
        Live
      </button>
      <button class="info small" @click="_switchSettings">
        <font-awesome-icon icon="fa-solid fa-sliders" v-if="!isSettingsOpened"/>
        <font-awesome-icon icon="fa-solid fa-times" v-else/>
      </button>
    </div>
    <div class="card mb-1" v-if="isSettingsOpened">
      <div class="card-header">
        Préférences
      </div>
      <div class="card-content text-left pb-2">
        <div>
          <h5>Comment sont calculés les xG ?</h5>
          <div class="mt-2 pl-2">
            <p>1. On calcule les forces offensives et défensives localisées (domicile/extérieur) des deux équipes en divisant la moyenne de buts marqués (ou encaissés) de l'équipe sur la saison par la moyenne correspondante de la ligue.</p>
            <p>2. On multiplie ensuite la force offensive de l'équipe par la force défensive de son adversaire. Puis, on multiplie cette valeur par la moyenne de buts marqués par rencontre dans la ligue.</p>
            <p>3. On obtient ainsi le nombre de buts attendus pour chaque équipe.</p>
          </div>
        </div>

        <div>
          <h5>Exemple : Annecy - Dunkerque</h5>
          <div class="mt-2 pl-2">
            <p>- Compétition - Nb. but moyen / match cette saison (ML) : 1.432</p>
            <p>- Annecy : Nb. but moyen marqué cette saison 1.555</p>
            <p>- Dunkerque : Nb. but moyen encaissé cette saison 0.9</p>
            <p>- Force offensive Annecy (FO) : 1.555 / 1.432 = 1.085</p>
            <p>- Force defensive Dunkerque (FD) : 0.9 / 1.432 = 0.628</p>
            <p>- FO * FD * ML = 1.085 * 0.628 * 1.432 = 0,97</p>
          </div>
        </div>
      </div>
    </div>
    <div v-if="_dataValid">
      <div class="card">
        <div class="card-header">
          Expected goals
        </div>
        <div class="card-content">
          <table>
            <tbody>
            <tr>
              <th class="sr">
              </th>
              <th class="sr">
                xG
              </th>
              <th v-for="nbGoal in _goals">
                {{ nbGoal }}
              </th>
            </tr>
            <tr>
              <td class="sr">
                <team-logo :uid="_homeTeamUID"/>
              </td>
              <td class="sr">
                {{ _homeXgFormatted }}
              </td>
              <td v-for="nbGoal in _goals"
                  :style="{'backgroundColor': getProbaColorTransparency(_getProba('home', nbGoal), 'home')}">
                {{ _getProba('home', nbGoal) }}%
              </td>
            </tr>
            <tr>
              <td class="sr">
                <team-logo :uid="_awayTeamUID"/>
              </td>
              <td class="sr">
                {{ _awayXgFormatted }}
              </td>

              <td v-for="nbGoal in _goals"
                  :style="{'backgroundColor': getProbaColorTransparency(_getProba('away', nbGoal), 'away')}">
                {{ _getProba('away', nbGoal) }}%
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="card mt-3">
        <div class="card-header">
          Scores les + probables
        </div>
        <div class="card-content p-1">
          <a-button-group v-for="score in _topScores" class="mx-1 inline-block" @click="_onSelectScore(score)">
            <button class="no-radius-right info">
              {{ score.value }}
            </button>
            <button class="no-radius-left info"
                    :style="{'backgroundColor': getProbaScoreColorTransparency(score.proba)}">{{ score.proba }}%
            </button>
          </a-button-group>
        </div>
      </div>

      <div class="card mt-3">
        <div class="card-header">
          Probabilité de chaque score
        </div>
        <div class="card-content">
          <table>
            <tbody>
            <tr>
              <td>
              </td>
              <td>
                <team-logo :uid="_awayTeamUID" size="small"/>
              </td>
              <td v-for="nbGoal in _goals">
                {{ nbGoal }}
              </td>
            </tr>
            <tr v-for="nbGoal in _goals">
              <td :rowspan="_goals.length" v-if="nbGoal < 1" class="sr">
                <team-logo :uid="_homeTeamUID" size="small"/>
              </td>

              <td>{{ nbGoal }}</td>
              <td v-for="nbGoal2 in _goals"
                  :style="{'backgroundColor': getProbaScoreColorTransparency(_getProbaScore(nbGoal, nbGoal2))}">
                {{ _getProbaScore(nbGoal, nbGoal2) }}%
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <a-alert v-else-if="!data" type="info" class="m-2" show-icon message="Chargement..."/>
    <a-alert v-else type="error" class="m-2" show-icon
             message="Les données disponibles ne permettent pas le calcul de probabibilité de chaque score."/>
    <hr class="mt-2"/>
    <p class="m-2">📊 Ces prédictions sont calculées grâce à la loi de Poisson, en se basant sur les résultats de la saison en cours.</p>
  </div>
</template>

<style scoped lang="less">
@import "@/assets/styles/variables.less";

table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  color: @text-color;
  font-size: @font-size-small;

  tr {
    border-bottom: 1px solid @stroke-color;

    &:last-child {
      border-bottom: none;
    }

    th, td {
      padding: @padding-small;
      text-align: center;
      font-family: 'SourceCodePro', serif;
      font-weight: 400;
    }

    th {
      background-color: @background-color-light;
    }

    td {
      &.label {
        text-orientation: mixed;
        writing-mode: vertical-rl;

        &.ll {
          border-left: 1px solid @stroke-color;
        }
      }
    }
  }
}
</style>
// stores/counter.js
import { defineStore } from 'pinia'
import axiosService from "@/services/axios.service";

export const liveStore = defineStore('liveStore', {
  state: () => {
    return {
      football: null
    }
  },
  actions: {
    // Permet de récupérer un match par son UID
    fetchAll(callback = null) {
      this.football = null
      axiosService.get('/dta/live-status').then(response => {
        console.log("liveStore", response.data)
        this.football = response.data.football
      }).finally(() => {
        if (callback) callback()
      });
    },

    set(sport, data) {
      switch (sport) {
        case 'FOOTBALL':
          this.football = data
          break
        default:
          console.error('liveStore.set', 'unknown sport', sport)
      }
      console.log("liveStore.set", this.football)
    }
  },
  getters: {
    getBySport: (state) => (sport) => {
      switch (sport) {
        case 'FOOTBALL':
          return state.football
        default:
          return null
      }
    },
    getBySportAndUID: (state) => (sport, uid) => {
      let bySport = liveStore().getBySport(sport);
      if (!bySport) {
        return null
      }

      let uidAsInt = parseInt(uid);

      let found = null
      bySport.forEach(item => {
        let gameUIDasInt = parseInt(item.gameUID);
        if (gameUIDasInt === uidAsInt) {
          found = item
        } else {
          //console.warn(gameUIDasInt, '!=', uidAsInt)
        }
      })
      return found
    }
  }
})
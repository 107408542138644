<script>
import LeagueName from "@/components/common/LeagueName.vue";
import {modalStore} from "@/stores/Modal.store";
import {leagueStore} from "@/stores/League.store";
import {contextStore} from "@/stores/Context.store";
import {sportStore} from "@/stores/Sport.store";
import LeagueButton from "@/components/common/LeagueButton.vue";

export default {
  name: "LicenceLeagueModal",
  components: {LeagueButton, LeagueName},
  data() {
    return {
      sport: 'FOOTBALL',
    }
  },
  computed: {
    _isVisible() {
      let visibleModal = modalStore().getVisibleModal;
      if (!visibleModal) return false;
      return visibleModal.uid === 'licenceLeagueModal';
    },
    _isMobile() {
      return contextStore().isMobileDevice()
    },
    _sports() {
      return sportStore().getAll;
    },
    _noneLeagues() {
      let results = leagueStore().getBySportAndLicenceType(this.sport, 'NONE');
      if(!results) return [];
      return results.sort((a, b) => a.area.localeCompare(b.area));
    },
    _basicLeagues() {
      let basicLeagues = leagueStore().getBySportAndLicenceType(this.sport, 'BASIC');

      // remover none leagues from basic leagues
      let noneLeagues = leagueStore().getBySportAndLicenceType(this.sport, 'NONE');
      basicLeagues = basicLeagues.filter(league => !noneLeagues.includes(league));
      if(!basicLeagues) return [];

      return basicLeagues.sort((a, b) => a.area.localeCompare(b.area));
    },
    _fullLeagues() {
      let fullLeagues = leagueStore().getBySportAndLicenceType(this.sport, 'FULL')

      // remove basic leagues from full leagues
      let basicLeagues = leagueStore().getBySportAndLicenceType(this.sport, 'BASIC');
      fullLeagues = fullLeagues.filter(league => !basicLeagues.includes(league));

      if(!fullLeagues) return [];

      return fullLeagues.sort((a, b) => a.area.localeCompare(b.area));
    },
  },
  methods: {
    _onCancel() {
      modalStore().closeModal();
    },

    _changeSport(sport) {
      this.sport = sport.code;
    },
  }
}
</script>

<template>
  <AModal v-if="_isVisible" :open="true" :width="600" @cancel="_onCancel" :class="{'mobile': _isMobile}">
    <header class="ant-modal-header">
      Compétitions disponibles
    </header>

    <div class="content">
      <fieldset class="m-1 p-1" v-if="_noneLeagues.length > 0">
        <legend>Base</legend>

        <league-button :league="league" v-for="league in _noneLeagues"/>
        <a-alert class="m-1" message="Ces compétitions sont accessibles gratuitement, sans compte ni connexion."  type="success" show-icon/>
      </fieldset>

      <fieldset class="m-1 p-1" v-if="_basicLeagues.length > 0">
        <legend>Pro (7€/an)</legend>
        <league-button :league="league" v-for="league in _basicLeagues"/>
      </fieldset>

      <fieldset class="m-1 p-1" v-if="_fullLeagues.length > 0">
        <legend>Ultimate (20€/an)</legend>
        <league-button :league="league" v-for="league in _fullLeagues"/>
      </fieldset>
    </div>

    <template #footer>
      <div class="flex p-2">

        <button :class="{'square':true, 'primary selected' : this.sport === sport.code}" v-for="sport in _sports"
                @click="_changeSport(sport)">
          <font-awesome-icon :icon="sport.icon"/>
        </button>

        <div class="flex-grow"/>
        <button class="info" @click="_onCancel()">
          Fermer
        </button>
      </div>
    </template>

  </AModal>
</template>

<style scoped lang="less">
</style>
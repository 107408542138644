Je comprends que vous ne souhaitez pas utiliser diagram-maker. Voici une alternative simple en utilisant HTML et CSS pour représenter l'arbre binaire des scores :

```vue
<script>
import {modalStore} from "@/stores/Modal.store";
import {contextStore} from "@/stores/Context.store";

export default {
  name: "ScoreEvolutionTreeModal",
  data() {
    return {
      scores: [
        {id: '0-0', score: '0-0', level: 0, x: 50, finalRate: 0.11, fromPrevious: 0.55},
        {id: '1-0', score: '1-0', level: 1, x: 25, finalRate: 0.11, fromPrevious: 0.55},
        {id: '0-1', score: '0-1', level: 1, x: 75, finalRate: 0.11, fromPrevious: 0.55},
        {id: '2-0', score: '2-0', level: 2, x: 12.5, finalRate: 0.11, fromPrevious: 0.55},
        {id: '1-1-from-1-0', score: '1-1', level: 2, x: 37.5, finalRate: 0.11, fromPrevious: 0.55},
        {id: '1-1-from-0-1', score: '1-1', level: 2, x: 60, finalRate: 0.11, fromPrevious: 0.55},
        {id: '0-2', score: '0-2', level: 2, x: 87.5, finalRate: 0.11, fromPrevious: 0.55}
      ],
      edges: [
        {from: '0-0', to: '1-0', classes: 'edge left'},
        {from: '0-0', to: '0-1', classes: 'edge right'},
        {from: '1-0', to: '2-0', classes: 'edge left'},
        {from: '1-0', to: '1-1-from-1-0', classes: 'edge right'},
        {from: '0-1', to: '1-1-from-0-1', classes: 'edge left'},
        {from: '0-1', to: '0-2', classes: 'edge right'}
      ]
    }
  },
  computed: {
    _isVisible() {
      let visibleModal = modalStore().getVisibleModal
      if (!visibleModal) return false
      return visibleModal.uid === 'scoreEvolutionTreeModal'
    },

    _isMobile() {
      return contextStore().isMobileDevice()
    },

    _payload() {
      let visibleModal = modalStore().getVisibleModal
      if (!visibleModal) return null
      return visibleModal.payload
    },

    _source() {
      if (!this._payload) return null
      return this._payload.source
    },

    _gameUID() {
      if (!this._payload) return null
      return this._payload.gameUID
    },
  },
  methods: {
    _onCancel() {
      modalStore().closeModal();
    },

    // Calcule la position des lignes qui connectent les nœuds
    getLineStyle(from, to) {
      const fromNode = this.scores.find(s => s.id === from);
      const toNode = this.scores.find(s => s.id === to);

      return {
        left: `${fromNode.x}%`,
        width: `${Math.abs(toNode.x - fromNode.x)}%`,
        top: `${fromNode.level * 100 + 50}px`,
        height: '50px',
        transform: toNode.x < fromNode.x ? 'translateX(-100%)' : '',
        borderRadius: toNode.x < fromNode.x ? '10px 0 0 0' : '0 10px 0 0'
      };
    }
  }
}
</script>

<template>
  <AModal v-if="_isVisible" :open="true" @cancel="_onCancel" :class="{'mobile': _isMobile}" :width="500">
    <header class="ant-modal-header">
      Évolution des Scores
    </header>
    <div class="content">
      <div class="tree-container">
        <!-- Nœuds -->
        <div
            v-for="score in scores"
            :key="score.id"
            class="node"
            :style="{
            left: `${score.x}%`, 
            top: `${score.level * 100 + 25}px`
          }"
        >
          <table>
            <tbody>
            <tr>
              <th colspan="2">{{ score.score }}</th>
            </tr>
            <tr>
              <td> {{score.finalRate}}</td>
              <td> {{score.fromPrevious}}</td>
            </tr>
            </tbody>
          </table>
        </div>

        <!-- Arêtes -->
        <div
            v-for="(edge, index) in edges"
            :key="index"
            :class="edge.classes"
            :style="getLineStyle(edge.from, edge.to)"
        ></div>
      </div>
    </div>

    <template #footer>
      <div class="my-2 mx-1">
        <button @click="_onCancel">Fermer</button>
      </div>
    </template>
  </AModal>
</template>

<style scoped lang="less">
.content {
  display: flex;
  justify-content: center;
  padding: 20px;
  min-height: 400px;
}

.tree-container {
  position: relative;
  width: 100%;
  height: 350px;
  overflow: visible;
}

.node {
  position: absolute;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  transform: translate(-50%, -50%);
  z-index: 2;
}

.edge {
  position: absolute;
  border-top: 1px solid #ccc;
  z-index: 1;
  &.left {
    border-left: 1px solid #ccc;
  }

  &.right {
    border-right: 1px solid #ccc;
  }
}

.mobile .tree-container {
  transform: scale(0.8);
  transform-origin: center top;
}
</style>
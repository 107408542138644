// stores/counter.js
import { defineStore } from 'pinia'
import axiosService from "@/services/axios.service";
import { contextStore } from "./Context.store";
import { modalStore } from "@/stores/Modal.store";

export const userStore = defineStore('userStore', {
  state: () => {
    return {
      loadingUIDs: [],
      fetchTeamByUIDTimeout: null,
      items: []
    }
  },
  actions: {

    showSignInModal(){
      console.log("showSignInModal")
      modalStore().openModal({uid: 'signInModal'})
    },

    signInWithGoogle() {
      this.signInProvider('google')
    },

    signInWithApple() {
      this.signInProvider('apple')
    },

    signInProvider(provider) {
      let uri = 'https://docto.bet/api'
      let redirect_uri = 'https://docto.bet'
      if (!import.meta.env.PROD) {
        uri = import.meta.env.VITE_API_URL // on prend l'url defini dans le .env
        redirect_uri = import.meta.env.VITE_WEB_URL // on prend l'url defini dans le .env
      }

      let destination = uri + '/oauth2/authorization/' + provider + '?redirect_uri=' + redirect_uri;
      console.info("redirect to " + provider + " sign in", destination)
      window.location.href = destination
    },

    // Permet de récupérer un match par son UID
    fetchByUID(uid) {

      if (!uid) return

      if (this.loadingUIDs.indexOf(uid) < 0) {
        this.loadingUIDs.push(uid)
      }

      // Si on a 100 équipes à charger, on le fait tout de suite
      const timeout = this.loadingUIDs.length < 100 ? 250 : 0

      if (this.fetchTeamByUIDTimeout) clearTimeout(this.fetchTeamByUIDTimeout)
      this.fetchTeamByUIDTimeout = setTimeout(() => {
        axiosService.get('/usr/' + this.loadingUIDs.join(',')).then(response => {
          this.items = this.items.concat(response.data.results)

          const loadedUIDs = response.data.results.map(item => item.uid)
          this.loadingUIDs = this.loadingUIDs.filter(uid => loadedUIDs.indexOf(uid) < 0)
        });

      }, timeout)
    }
    ,


    initCode: (nickname, message, callback) => {
      const payload = {
        nickname: nickname,
        message: message
      }
      axiosService.put('/usr/code-parrain', payload).then(response => {
        contextStore().initUser()
        console.log("callback", callback)
        if (callback) callback()
      })
    }
  },
  getters: {

    get: (state) => (uid) => {

      if (state.loadingUIDs.indexOf(uid) >= 0) {
        return null
      }

      let item = state.items.find(item => item.uid === uid);
      if (item) {
        return item
      }

      // Si la team n'est pas dans le store, on le charge
      const ts = userStore()
      ts.fetchByUID(uid)

      return null
    },

    getAll: (state) => {
      return state.items
    }
  }
})
<script>
import {contextStore} from "@/stores/Context.store";
import MainHeader from "@/components/common/MainHeader.vue";
import TeamLogo from "@/components/common/TeamLogo.vue";
import StatSelectorModal from "@/components/common/modal/StatSelector.Modal.vue";
import WidgetParameterSelectorModal from "@/components/common/modal/WidgetParameterSelector.Modal.vue";
import UserAccountPreferences from "@/components/common/modal/user_preferences/category/UserAccountPreferences.vue";
import CalculatorModal from "@/components/common/modal/calculator/CalculatorModal.vue";
import UserPreferencesModal from "@/components/common/modal/user_preferences/UserPreferencesModal.vue";
import WidgetSettingsModal from "@/components/common/modal/widget_settings/WidgetSettingsModal.vue";
import PerspectiveSettingsModal from "@/components/common/modal/perspective/PerspectiveSettingsModal.vue";
import {statsRouteStore} from "@/components/routes/stats/StatsRoute.store";
import SearchModal from "@/components/common/modal/search/SearchModal.vue";
import GameModal from "@/components/common/modal/game/GameModal.vue";
import TeamModal from "@/components/common/modal/team/TeamModal.vue";
import Statistic from "@/components/common/Statistic.vue";
import PaypalModal from "@/components/common/modal/paypal/PaypalModal.vue";
import EventStatsModal from "@/components/routes/stats/modals/FrequencyStatsModal.vue";
import FrequencyStatsModal from "@/components/routes/stats/modals/FrequencyStatsModal.vue";
import GameDetailsModal from "@/components/common/modal/game/GameDetailsModal.vue";
import LeagueGroupsModal from "@/components/common/modal/selection_league_modal/LeagueGroupsModal.vue";
import LeagueGroupModal from "@/components/common/modal/selection_league_modal/LeagueGroupModal.vue";
import SerieStatsModal from "@/components/routes/stats/modals/SerieStatsModal.vue";
import YoutubeButton from "@/components/common/YoutubeButton.vue";
import {userStore} from "@/stores/User.store";

// Widgets
import FrequencyStatsWidget from "@/components/routes/stats/widgets/FrequencyStats.Widget.vue";
import RawStatsWidget from "@/components/routes/stats/widgets/RawStats.Widget.vue";
import SerieStatsWidget from "@/components/routes/stats/widgets/SerieStats.Widget.vue";
import RawStatsModal from "@/components/routes/stats/modals/RawStatsModal.vue";
import GameSelectionStatsWidget from "@/components/routes/stats/widgets/GameSelection.Stats.Widget.vue";
import {statStore} from "@/stores/Statistic.store";
import StatsPane from "@/components/routes/stats/panes/stats/StatsPane.vue";
import AnalysePane from "@/components/routes/selection/panes/analyse/AnalysePane.vue";
import StatsDataPane from "@/components/routes/stats/panes/data/StatsDataPane.vue";
import StatsPaneFilters from "@/components/routes/stats/panes/stats/filters/StatsPane.Filters.vue";

export default {
  name: "StatsRoute",
  components: {
    StatsPaneFilters,
    StatsDataPane,
    AnalysePane,
    StatsPane,
    YoutubeButton,
    SerieStatsModal,
    RawStatsModal,
    FrequencyStatsModal,
    LeagueGroupModal,
    LeagueGroupsModal,
    GameDetailsModal,
    EventStatsModal,
    PaypalModal,
    Statistic,
    TeamModal,
    GameModal,
    SearchModal,
    PerspectiveSettingsModal,
    WidgetSettingsModal,
    UserPreferencesModal,
    CalculatorModal,
    UserAccountPreferences,
    WidgetParameterSelectorModal,
    StatSelectorModal, TeamLogo, MainHeader,
    // widgets
    FrequencyStatsWidget,
    RawStatsWidget,
    SerieStatsWidget,
    GameSelectionStatsWidget,
  },
  computed: {
    _isMobile() {
      return contextStore().isMobileDevice()
    },

    _notAuthenticated() {
      return !contextStore().isAuthenticated()
    },
    _overContext() {
      return statsRouteStore().getOverContext
    },
    _hoverStat(){
      if(!this._overContext) return null
      if(!this._overContext.statUID) return null
      return statStore().get(this._overContext.statUID)
    },
    _hoverItemLabel() {
      if(!this._overContext) return 'Over Context not found'
      if(!this._hoverStat) return 'Over Stat not found'
      return statStore().getLabel(this._hoverStat, this._overContext.period)
    },
  },
  methods: {
    _signWithGoogle() {
      userStore().showSignInModal()
    }
  },
  beforeMount() {

    if (contextStore().shouldReload()) {
      window.location.href = window.location.origin
    }

    if (!contextStore().isInitialized()) {
      this.$router.push({name: 'init', query: {redirect: 'stats', query: this._urlUID}})
      return
    }

    statsRouteStore().init()
  }
}
</script>

<template>
  <div :class="{'mobile': _isMobile}">
    <main-header/>
    <div class="flex">
      <stats-pane v-if="!_isMobile"/>
      <stats-data-pane/>
    </div>

    <div id="just-sign-in" v-if="_notAuthenticated">
      <h2 class="p-2 pt-4 uppercase">Plus de stats ?</h2>
      <p class="px-3">Commence par créer ton compte pour débuter ton aventure DoctoBET ! Cela ne prend que quelques secondes !</p>
      <button class="primary mb-4" @click="_signWithGoogle()">Créer mon compte</button>
    </div>

  </div>
</template>

<style scoped lang="less">
@import "@/assets/styles/variables.less";

#stat-pane {
  margin: @padding-small;
  border-radius: @radius-regular;
  background: rgb(94,53,43);
  background: linear-gradient(122deg, rgba(94,53,43,1) 0%, rgba(94,53,43,0.95) 3%, rgba(37,37,42,0.85) 21%, rgba(37,37,42,0.75) 100%);
  height: calc(100dvh - @toolbar-regular);
}


#just-sign-in{
  position: fixed;
  bottom: 12px;
  right: 12px;
  width: 300px;
  border: 1px solid @stroke-color;
  border-radius: @radius-regular;
  background: #494081;

  p{
    text-align: center;
  }
}

</style>
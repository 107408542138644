<script>
import BankrollResume from "@/components/common/BankrollResume.vue";
import {bankrollStore} from "@/stores/Bankroll.store";
import {modalStore} from "@/stores/Modal.store";

export default {
  name: "SelectionMonitoringItem",
  components: {BankrollResume},
  props: {
    item: {
      type: Object,
      required: true,
    }
  },
  computed: {
    _bankrollUID() {
      if (!this.item) return null
      return this.item.bankrollUID
    },
    _bankroll() {
      if (!this._bankrollUID) return 'Bankroll introuvable : #?'
      return bankrollStore().get(this._bankrollUID)
    },
    _bankrollName() {
      if (!this._bankroll) return 'Bankroll introuvable : #' + this._bankrollUID
      let name = this._bankroll.name;
      // keep only the first 20 characters
      if (name.length > 25) {
        name = name.substring(0, 25) + '...'
      }
      return name
    },
    _bankrollWinRate() {
      if (!this._bankroll) return 0.0
      return (this._bankroll.winRate * 100.0).toFixed(0)
    },
    _bankrollTicketCount() {
      if (!this._bankroll) return 0
      let valueAsInt = this._bankroll.ticketDoneCount;

      // Transform : ex 1711 => 1.7k
      if (valueAsInt > 1000) {
        return (valueAsInt / 1000).toFixed(1) + 'k'
      }

      return valueAsInt
    },
    _bankrollROI() {
      if (!this._bankroll) return 0.0
      return (this._bankroll.roi * 100.0).toFixed(1)
    },
    _bankrollColor() {
      if (!this._bankroll) return 'grey'
      if (this._bankroll.balance >= this._bankroll.initialCapital) return '#6DFFC2'
      return '#FF846E'
    },
    _esperanceColor(){
      if (!this._bankroll) return 'grey'
      if (this._bankrollEsperance >= 0) return '#6DFFC2'
      return '#FF846E'
    },
    _bankrollLast5() {
      if (!this._bankroll) return ['X', 'X', 'X', 'X', 'X']
      // split the last 5 tickets in an array of 5 elements
      let last5 = this._bankroll.last5Tickets.split('')
      while (last5.length < 5) {
        last5.unshift('X')
      }
      return last5.reverse()
    },
    _bankrollAverageOdd(){
      if (!this._bankroll) return 0.0
      return this._bankroll.averageOdd.toFixed(2)
    },
    _bankrollEsperance(){
      // calcul de l'espérance de gain
      if (!this._bankroll) return 0.0
      if (!this._bankroll.winRate) return 0.0
      let value = (this._bankrollAverageOdd - 1.0) * this._bankroll.winRate - (1.0 - this._bankroll.winRate);
      return (value * 100.0).toFixed(0)
    },
    _monitoringWaitingBetsCount() {
      if (!this._bankroll) return 0
      return this._bankroll.ticketWaitCount
    }
  },
  methods: {
    _openMonitoringList() {
      modalStore().openModal({
        uid: "selectionMonitoringListModal",
        payload: {selectionUID: this.item.selectionUID}
      })
    },

    _goBankroll() {
      this.$router.push({name: 'bankrolls', query: {uid: this._bankrollUID}})
    },
    _ticketColor(ticket) {
      if (ticket === 'W') return '#6DFFC2'
      if (ticket === 'L') return '#FF846E'
      if (ticket === 'H') return '#FFC86D'
      return 'grey'
    }
  }
}
</script>

<template>
  <div class="selection-monitoring">
    <table>
      <tbody>
      <tr>
        <th colspan="2" class="text-left">{{ _bankrollName }}</th>
      </tr>
      <tr>
        <td class="text-left">
          Réu
          <span :style="{'color': _bankrollColor, 'width' : '64px'}">{{ _bankrollWinRate }}%</span>/{{ _bankrollTicketCount }}

        </td>
        <td class="text-left">
          ROI
          <span :style="{'color': _bankrollColor, 'width' : '64px'}">{{ _bankrollROI }}%</span>
        </td>
        <td>
          EM <span :style="{'color': _esperanceColor, 'width' : '64px'}">{{ _bankrollEsperance }}%</span>
        </td>
      </tr>
      <tr>
        <td class="text-left">
          L5
          <font-awesome-icon icon="fa-solid fa-circle"
                             v-for="i in _bankrollLast5"
                             class="mr-1" :style="{'width': '8px', 'color': _ticketColor(i)}"/>
        </td>
        <td style=" text-align: right">
          <button class="small info mr-1" @click.stop="_openMonitoringList"
                  v-if="_monitoringWaitingBetsCount > 0">{{ _monitoringWaitingBetsCount }} bets
          </button>
          <button class="small info" @click.stop="_goBankroll">
            <font-awesome-icon icon="fa-wallet"/>
          </button>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<style scoped lang="less">

</style>
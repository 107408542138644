<script>
import {perspectiveStore} from "@/stores/Perspective.store";
import {contextStore} from "@/stores/Context.store";
import {modalStore} from "@/stores/Modal.store";
import {message} from "ant-design-vue";
import {statsRouteStore} from "@/components/routes/stats/StatsRoute.store";

export default {
  name: "PerspectiveSettingsModal",

  data() {
    return {
      form: {
        name: null,
        description: null,
      },
    }
  },

  methods: {
    _onCancel() {
      modalStore().closeModal();
    },
    _onValid() {
      if (this._isPerspectiveYours) {
        perspectiveStore().update(this._perspectiveUID, this.form)
      }
      if (this._isCreation) {
        if (this._onCreate) {
          this._onCreate(this.form)
        }
      }

      modalStore().closeModal();
    },
    _deletePerspective() {
      if (!this._isPerspectiveYours) return

      // On vérifie que ce n'est pas la dernière perspective
      const allPerspectives = perspectiveStore().getBySportAndType(this._perspective.sport, this._perspective.type)
      if (allPerspectives.length === 1) {
        message.error("Tu ne peux pas supprimer toutes tes perspectives.")
        return
      }
      perspectiveStore().delete(this._perspectiveUID, () => {
        statsRouteStore().selectFirstPerspective()
        modalStore().closeModal();
      })
    }
  },

  computed: {
    _isVisible() {
      let visibleModal = modalStore().getVisibleModal;
      if (!visibleModal) return false;
      return visibleModal.uid === 'perspectiveSettingsModal';
    },
    _perspectiveUID() {
      let visibleModal = modalStore().getVisibleModal;
      if (!visibleModal) return null;
      return visibleModal.payload.uid
    },
    _isCreation() {
      let visibleModal = modalStore().getVisibleModal;
      if (!visibleModal) return null;
      return visibleModal.payload.creation
    },
    _isPerspectiveYours() {
      if (!this._perspective) return false
      return this._perspective.userUID === contextStore().getLoggedUserUID()
    },
    _perspective() {
      return perspectiveStore().get(this._perspectiveUID)
    },
    _payload() {
      let visibleModal = modalStore().getVisibleModal;
      if (!visibleModal) return null;
      return visibleModal.payload
    },
    _onCreate() {
      let visibleModal = modalStore().getVisibleModal;
      if (!visibleModal) return null;
      return visibleModal.payload.onCreate
    }
  },

  watch: {
    _isVisible(v) {
      if (v) {
        if(!this._perspective) return
        this.form.name = this._perspective.name
        this.form.description = this._perspective.description
      }
    }
  }

}
</script>

<template>
  <AModal v-if="_isVisible" :open="true" :width="500" @cancel="_onCancel">
    <header class="ant-modal-header">
      Configuration de la perspective
    </header>

    <div class="sb px-2 py-1 flex">
      <label class="w-1/5">Nom</label>
      <div class="w-4/5 flex">
        <input class="flex-grow" v-model="form.name" placeholder="Nom de la perspective..."
               :disabled="!_isPerspectiveYours">
      </div>
    </div>
    <div class="sb px-2 py-1">
      <label class="">Description</label>
      <div class="flex">
        <textarea class="flex-grow" v-model="form.description" placeholder="Description de la perspective..."
                  maxlength="200" :disabled="!_isPerspectiveYours"/>
      </div>
    </div>

    <template #footer>
      <div class="m-2 flex">
        <button class="secondary" @click="_deletePerspective" v-if="_isPerspectiveYours">
          Supprimer cette perspective
        </button>
        <div class="flex-grow"></div>
        <button class="primary" @click="_onValid">Valider</button>
      </div>
    </template>

  </AModal>
</template>

<style scoped lang="less">

</style>
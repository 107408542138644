<script>
import dateService from "@/services/date.service";
import {contextStore} from "@/stores/Context.store";
import axiosService from "@/services/axios.service";
import {modalStore} from "@/stores/Modal.store";
import {leagueStore} from "@/stores/League.store";
import {message} from "ant-design-vue";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

export default {
  name: "UserAccountPreferences",
  components: {FontAwesomeIcon},
  data() {
    return {
      prices: null,

      formules: {
        free: {
          open: false,
        },
        basic: {
          open: false,
        },
        full: {
          open: false,
        },
      }
    }
  },

  mounted() {
    // get prices from the server
    axiosService.get('/fin/prices')
        .then(response => {
          this.prices = response.data;
        })
        .catch(error => {
          console.error('Cannot load prices', error);
        });

    const licenceType = contextStore().getLicenseType()
    this.formules.basic.open = licenceType === 'NONE'
    this.formules.full.open = licenceType === 'BASIC'
  },

  computed: {
    _contextStore() {
      return contextStore();
    },
    _loggedUser() {
      return this._contextStore.loggedUser;
    },
    _anonymousEmail() {
      if (!this._loggedUser) return "";
      return this._loggedUser.anonymousEmail;
    },
    _licenceExpirationDate() {
      if (!this._loggedUser) return null;
      return this._loggedUser.licenceExpirationDate;
    },
    _formattedLicenceExpirationDate() {
      if (!this._licenceExpirationDate) return "";
      return dateService.formatDDMMYYYY(this._licenceExpirationDate);
    },
  },

  methods: {

    _showLeagues() {
      modalStore().openModal({
        uid: 'licenceLeagueModal'
      })
    },

    _buy(formule) {
      if (!formule) return

      if (formule === 'BASIC' && contextStore().getLicenseType() === 'FULL') {
        message.info('Tu as déjà un abonnement supérieur.')
        return
      }

      modalStore().openModal({
        uid: 'paypalModal', payload: {
          formule: formule
        }
      })
    },

    _formuleBtnType(formule) {
      if (!this.prices) return 'info'
      switch (formule) {
        case 'NONE':
          return 'success'
        case 'BASIC':
          if (contextStore().getLicenseType() === 'FULL') {
            return 'success'
          }
          return 'info'
        case 'FULL':
          return 'info'
      }
    },
    _formuleExpirationDate(formule){
      switch (formule) {
        case 'NONE':
          return 'à vie'
        case 'BASIC':
          if (contextStore().isLicensed()) {
            return this._formattedLicenceExpirationDate
          }
          return null
        case 'FULL':
          if (contextStore().getLicenseType() === 'FULL') {
            return this._formattedLicenceExpirationDate
          }
          return null
      }
    },
    _formuleBtnLabel(formule) {
      if (!this.prices) return '...'
      switch (formule) {
        case 'NONE':
          return null
        case 'BASIC':

          if (contextStore().getLicenseType() === 'FULL') {
            return null
          }

          if (!contextStore().isLicensed()) {
            if (!this.prices.basic) return 'Obtenir pour ...'
            return '1 an pour ' + this.prices.basic.amountFromPaypal + '€'
          }

          return '1 an de + pour ' + this.prices.basic.amountFromPaypal + '€'
        case 'FULL':
          if (contextStore().getLicenseType() !== 'FULL') {
            if (!this.prices.full) return 'Obtenir pour ...'
            return '1 an pour ' + this.prices.full.amountFromPaypal.toFixed(1) + '€'
          }

          return '1 an de + pour ' + this.prices.full.amountFromPaypal.toFixed(1) + '€'
      }
    },
    _countLeagues(sport, formule) {
      let leagues = leagueStore().getBySportAndLicenceType(sport, formule);
      if (!leagues) return 0
      return leagues.length
    },
    _countLeaguesByFormule(formule) {
      let leagues = leagueStore().getByLicenceType(formule);
      if (!leagues) return 0
      return leagues.length
    }
  },
}
</script>

<template>
  <div>
    <fieldset class="mt-2 no-border">
      <legend>Informations personnelles</legend>
      <div class="px-2 py-1">
        <label class="inline-block mr-2">Mail</label>
        <label class="inline-block ml-2" style="opacity: 0.25; font-size: 0.75rem">{{ _anonymousEmail }}</label>
      </div>

    </fieldset>
    <hr/>

    <fieldset class="mt-2 no-border">
      <legend>Formule d'abonnement</legend>
      <div class="px-2">
        <div class="formule mb-2" @click="formules.free.open = !formules.free.open">
          <div class="flex items-center justify-center px-2">
            <label class="mr-2">Base</label>
            <a-alert :message="_formuleExpirationDate('NONE')" v-if="_formuleExpirationDate('NONE')" show-icon type="success"></a-alert>
            <div class="flex-grow"/>
            <button class="info my-1" @click.stop="_showLeagues()">
              Compétitions disponibles
              <font-awesome-icon icon="fa-solid fa-trophy"/>
            </button>
          </div>
          <div v-show="formules.free.open">
            <hr/>
            <ul class="p-2">
              <li>
                Bonus de parrainage de <b>5%</b>.
              </li>
              <li>
                Accès limité aux rencontres dans les 24 heures.
              </li>
              <li>
                Accès à <b>{{ _countLeagues('FOOTBALL', 'NONE') }} ligues</b> Football
              </li>
            </ul>
          </div>
        </div>
        <div class="formule mb-2" @click="formules.basic.open = !formules.basic.open">
          <div class="flex items-center justify-center px-2">
            <label class="mr-2">Pro</label>
            <a-alert class="my-1" :message="_formuleExpirationDate('BASIC')" v-if="_formuleExpirationDate('BASIC')" show-icon type="success"></a-alert>
            <div class="flex-grow"/>
            <button @click.stop="_buy('BASIC')" style="cursor: pointer"  class="accent my-1" v-if="_formuleBtnLabel('BASIC')">
              {{_formuleBtnLabel('BASIC')}}
            </button>
          </div>
          <div v-show="formules.basic.open">
            <hr/>
            <ul class="p-2">
              <li>
                Bonus de parrainage de <b>10% (+5% niveau 2)</b>.
              </li>
              <li>
                Accès à toutes les rencontres sur <b>7 jours</b>.
              </li>
              <li>
                Accès à <b>{{ _countLeagues('FOOTBALL', 'BASIC') }} ligues</b> Football
              </li>
            </ul>
          </div>
        </div>
        <div class="formule mb-2" @click="formules.full.open = !formules.full.open">
          <div class="flex items-center justify-center px-2">
            <label class="mr-2">Ultimate</label>
            <a-alert class="my-1" :message="_formuleExpirationDate('FULL')" v-if="_formuleExpirationDate('FULL')" show-icon type="success"></a-alert>
            <div class="flex-grow"/>
            <button @click.stop="_buy('FULL')" style="cursor: pointer"  class="accent my-1" v-if="_formuleBtnLabel('FULL')">
              {{_formuleBtnLabel('FULL')}}
            </button>
          </div>
          <div v-show="formules.full.open">
            <hr/>
            <ul class="p-2">
              <li>
                Bonus de parrainage de <b>15% (+10% niveau 2)</b>.
              </li>
              <li>
                Accès à toutes les rencontres sur <b>7 jours</b>.
              </li>
              <li>
                Accès à <b>{{ _countLeagues('FOOTBALL', 'FULL') }} ligues</b> Football
              </li>
            </ul>
          </div>
        </div>
      </div>
    </fieldset>

  </div>
</template>

<style scoped lang="less">
@import "@/assets/styles/variables.less";

.formule {
  border: 1px solid @stroke-color;
  border-radius: @radius-regular;

  ul {
    margin: 0;

    li {
      list-style-type: disc;
      margin-left: 1rem;
      color: @text-color;
    }
  }
}
</style>
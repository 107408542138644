// stores/counter.js
import { defineStore } from 'pinia'

// WebSocket
import Stomp from 'webstomp-client'
import SockJS from 'sockjs-client';
import { liveStore } from "@/stores/Live.store";

export const webSocketStore = defineStore('webSocketStore', {
  state: () => {
    return {
      connected: false,
      client: null
    }
  },
  actions: {
    connect() {
      const uri = import.meta.env.VITE_WS_URL
      const socket = new SockJS(uri, null, {
        transports: ['websocket', 'xhr-streaming', 'xhr-polling', 'jsonp-polling'],
        debug: false
      })
      this.client = Stomp.over(socket)
      console.info("[websocket] connecting to ", uri)
      this.client.hasDebug = false
      this.client.connect({}, (frame) => {
        this.connected = true
        this._onConnectSuccess(frame)
      }, (error) => {
        this.connected = false
        this._onConnectFailed(error)
      })
    },

    _onConnectSuccess(frame) {
      console.log(`[websocket] connected: ${frame}`)
      this.client.subscribe('/topic/common', message => {
        // console.log(`Received common message: ${message.body}`)
      })

      this.client.subscribe('/topic/live/football', message => {
        // console.log(`Received live/football message: ${message.body}`)
        const msgAsJson = JSON.parse(message.body)
        if(!msgAsJson || !msgAsJson.content) {
          console.error('[websocket] invalid message format', message.body)
          return
        }
        //console.log(`[websocket] football message: ${msgAsJson}`)
        const content = msgAsJson.content
        //console.log(`[websocket] football content: ${content}`)
        liveStore().set('FOOTBALL', content)
      })

      // beforeunload event to disconnect the client
      window.addEventListener('beforeunload', this.disconnect);
    },

    _onConnectFailed(error) {
      console.error(`[websocket] connection failed: ${error}`)
      this.connected = false
      setTimeout(() => {
        console.log('[websocket] retrying connection in 5 seconds...')
        this.connect()
      }, 5000)
    },

    disconnect() {
      if (this.client) {
        this.client.disconnect(() => {
          console.log('[websocket] disconnected')
          this.connected = false
        })
      }
    },

    subscribe(topic, onMessage) {
      this.client.subscribe(topic, onMessage, null)
    },

    unsubscribe(topic) {
      this.client.unsubscribe(topic)
    }
  },
  getters: {}
})
<script>
import axiosService from "@/services/axios.service";
import {gameStore} from "@/stores/Game.store";

import PlayerName from "../../../../../../common/PlayerName.vue";
import PlayerPicture from "@/components/common/PlayerPicture.vue";
import SimpleGameRow from "@/components/routes/selection/panes/selection/items/tabs/SimpleGameRow.vue";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import {preferencesStore} from "@/stores/Preferences.store";
import YoutubeButton from "@/components/common/YoutubeButton.vue";
import {contextStore} from "@/stores/Context.store";
import Statistic from "@/components/common/Statistic.vue";
import {modalStore} from "@/stores/Modal.store";

export default {
  name: "SelectionPaneItem.Similar.Tab",
  components: {Statistic, YoutubeButton, FontAwesomeIcon, SimpleGameRow, PlayerPicture, PlayerName},

  props: {
    gameUid: {
      required: true
    }
  },

  data() {
    return {

      filters: {
        on1X2: true,
        on1X2Margin: 0.025,
        onUnderOver25: true,
        onUnderOver25Margin: 0.025,
        onBTTS: true,
        onBTTSMargin: 0.025,
        onLeague: false,
        onHomeTeam: false,
        onAwayTeam: false,
      },

      data: null,
      refreshTimeout: null,

      isSettingsOpened: false
    }
  },

  beforeMount() {

    // Init filters from preferences
    this.filters.on1X2 = preferencesStore().getBoolean('SELECTION_SIMILAR_TAB', 'ON_1X2', true)
    this.filters.on1X2Margin = preferencesStore().getFloat('SELECTION_SIMILAR_TAB', 'ON_1X2_MARGIN', 0.025)
    this.filters.onUnderOver25 = preferencesStore().getBoolean('SELECTION_SIMILAR_TAB', 'ON_UNDER_OVER_25', true)
    this.filters.onUnderOver25Margin = preferencesStore().getFloat('SELECTION_SIMILAR_TAB', 'ON_UNDER_OVER_25_MARGIN', 0.025)
    this.filters.onBTTS = preferencesStore().getBoolean('SELECTION_SIMILAR_TAB', 'ON_BTTS', true)
    this.filters.onBTTSMargin = preferencesStore().getFloat('SELECTION_SIMILAR_TAB', 'ON_BTTS_MARGIN', 0.025)
    this.filters.onLeague = preferencesStore().getBoolean('SELECTION_SIMILAR_TAB', 'ON_LEAGUE', false)
    this.filters.onHomeTeam = preferencesStore().getBoolean('SELECTION_SIMILAR_TAB', 'ON_HOME_TEAM', false)
    this.filters.onAwayTeam = preferencesStore().getBoolean('SELECTION_SIMILAR_TAB', 'ON_AWAY_TEAM', false)
    this.filters.moment = preferencesStore().getString('SELECTION_SIMILAR_TAB', 'MOMENT', 'FT')

    this._refreshNow()
  },

  computed: {
    _game() {
      return gameStore().get(this.gameUid)
    },
    _hasFullLicence() {
      return contextStore().hasFullLicence();
    },
    _dataValid() {
      return this.data && this.data.gameUIDs && this.data.gameUIDs.length > 0
    },
    _gameUIDs() {
      if (!this.data) return []
      return this.data.gameUIDs
    },
    _gameUIDsLength() {
      return this._gameUIDs.length
    },
    _gameFoundMessages() {
      if(this._gameUIDsLength === 0) return 'Aucun match similaire trouvé. Augmentes les marges pour plus de résultats.'
      if(this._gameUIDsLength < 100) return `Seulement ${this._gameUIDsLength} matchs similaires trouvés. Augmentes les marges pour plus de résultats.`
      return this._gameUIDsLength + ' matchs similaires trouvés.'
    },
    _gameFoundType() {
      if(this._gameUIDsLength === 0) return 'error'
      if(this._gameUIDsLength < 100) return 'warning'
      return 'success'
    },
    _homeWinRate() {
      if (this._gameUIDsLength === 0) return 0
      return (this.data.homeWinGames.length / this._gameUIDsLength * 100.0).toFixed(0)
    },
    _awayWinRate() {
      if (this._gameUIDsLength === 0) return 0
      return (this.data.awayWinGames.length / this._gameUIDsLength * 100.0).toFixed(0)
    },
    _drawRate() {
      if (this._gameUIDsLength === 0) return 0
      return (this.data.drawGames.length / this._gameUIDsLength * 100.0).toFixed(0)
    },
    _over05Rate() {
      if (this._gameUIDsLength === 0) return 0
      return (this.data.over05Games.length / this._gameUIDsLength * 100.0).toFixed(0)
    },
    _over15Rate() {
      if (this._gameUIDsLength === 0) return 0
      return (this.data.over15Games.length / this._gameUIDsLength * 100.0).toFixed(0)
    },
    _over25Rate() {
      if (this._gameUIDsLength === 0) return 0
      return (this.data.over25Games.length / this._gameUIDsLength * 100.0).toFixed(0)
    },
    _over35Rate() {
      if (this._gameUIDsLength === 0) return 0
      return (this.data.over35Games.length / this._gameUIDsLength * 100.0).toFixed(0)
    },
    _over45Rate() {
      if (this._gameUIDsLength === 0) return 0
      return (this.data.over45Games.length / this._gameUIDsLength * 100.0).toFixed(0)
    },
    _over55Rate() {
      if (this._gameUIDsLength === 0) return 0
      return (this.data.over55Games.length / this._gameUIDsLength * 100.0).toFixed(0)
    },
    _over65Rate() {
      if (this._gameUIDsLength === 0) return 0
      return (this.data.over65Games.length / this._gameUIDsLength * 100.0).toFixed(0)
    },
    _bttsRate() {
      if (this._gameUIDsLength === 0) return 0
      return (this.data.bttsGames.length / this._gameUIDsLength * 100.0).toFixed(0)
    },
    _goalsSum() {
      if (!this.data) return 0
      return this.data.homeGoalsCount + this.data.awayGoalsCount
    },
    _goalsAvg() {
      if (this._gameUIDsLength === 0) return 0
      return (this._goalsSum / this._gameUIDsLength).toFixed(2)
    },
    _homeGoalsAvg() {
      if (this._gameUIDsLength === 0) return 0
      return (this.data.homeGoalsCount / this._gameUIDsLength).toFixed(2)
    },
    _awayGoalsAvg() {
      if (this._gameUIDsLength === 0) return 0
      return (this.data.awayGoalsCount / this._gameUIDsLength).toFixed(2)
    },
    _scoreRates() {
      if (!this.data) return []
      const results = {}
      Object.keys(this.data.scoreCountMap).forEach(item => {
        results[item] = (this.data.scoreCountMap[item].length / this._gameUIDsLength)
      })
      return results
    },
    _topScoresRate() {
      // sort _scoreRates by value
      const sorted = Object.keys(this._scoreRates).sort((a, b) => this._scoreRates[b] - this._scoreRates[a])
      return sorted.slice(0, 6)
    },
    _getHighestProbaScore() {
      if (!this._scoreRates) return 1
      return this._scoreRates[this._topScoresRate[0]]
    },
    _marginOptions() {
      // return all value between 0.01 and 0.03 by 0.0025
      const results = []
      for (let margin = 0.01; margin <= 0.030001; margin += 0.005) {
        // round to 4 decimals, to avoid floating point issues
        results.push(Math.round(margin * 10000) / 10000)
      }
      return results
    },
    _sport() {
      return contextStore().getSport
    },
    _canUseUnderOver25() {
      switch (this._sport) {
        case 'FOOTBALL':
          return true
        default:
          return false
      }
    },
    _canUseBTTS() {
      switch (this._sport) {
        case 'FOOTBALL':
          return true
        default:
          return false
      }
    },
    _shouldShowBtts() {
      switch (this._sport) {
        case 'FOOTBALL':
        case 'HOCKEY':
          return true
        default:
          return false
      }
    },
    _shouldShowUnderOver() {
      return this._shouldShowUnderOver05 || this._shouldShowUnderOver15 || this._shouldShowUnderOver25 || this._shouldShowUnderOver35 || this._shouldShowUnderOver45 || this._shouldShowUnderOver55 || this._shouldShowUnderOver65
    },
    _shouldShowUnderOver05() {
      switch (this._sport) {
        case 'FOOTBALL':
          return true
        default:
          return false
      }
    },
    _shouldShowUnderOver15() {
      switch (this._sport) {
        case 'FOOTBALL':
          return true
        default:
          return false
      }
    },
    _shouldShowUnderOver25() {
      switch (this._sport) {
        case 'FOOTBALL':
          return true
        default:
          return false
      }
    },
    _shouldShowUnderOver35() {
      switch (this._sport) {
        case 'FOOTBALL':
        case 'HOCKEY':
          return true
        default:
          return false
      }
    },
    _shouldShowUnderOver45() {
      switch (this._sport) {
        case 'HOCKEY':
          return true
        default:
          return false
      }
    },
    _shouldShowUnderOver55() {
      switch (this._sport) {
        case 'HOCKEY':
          return true
        default:
          return false
      }
    },
    _shouldShowUnderOver65() {
      switch (this._sport) {
        case 'HOCKEY':
          return true
        default:
          return false
      }
    }
  },

  methods: {
    _refresh() {
      this.data = null // => loading
      clearTimeout(this.refreshTimeout)
      this.refreshTimeout = setTimeout(() => {
        this._refreshNow()
      }, 200)
    },
    async _refreshNow() {
      if (!this.gameUid) return

      if (!this._hasFullLicence) {
        return
      }

      if (!this.filters.on1X2 && !this.filters.onUnderOver25 && !this.filters.onBTTS) {
        this.data = {
          gameUIDs: []
        }
        return
      }

      const payload = {
        gameUID: this.gameUid,
        moment: this.filters.moment,
        on1X2: this.filters.on1X2,
        on1X2Margin: this.filters.on1X2Margin,
        onUnderOver25: this._canUseUnderOver25 && this.filters.onUnderOver25,
        onUnderOver25Margin: this.filters.onUnderOver25Margin,
        onBTTS: this._canUseBTTS && this.filters.onBTTS,
        onBTTSMargin: this.filters.onBTTSMargin,
        onLeague: this.filters.onLeague,
        onHomeTeam: this.filters.onHomeTeam,
        onAwayTeam: this.filters.onAwayTeam,
        odd1X2HomeValue: this._game.odd1X2HomeValue,
        odd1X2DrawValue: this._game.odd1X2DrawValue,
        odd1X2AwayValue: this._game.odd1X2AwayValue,
        oddUO25UnderValue: this._game.oddUO25UnderValue,
        oddUO25OverValue: this._game.oddUO25OverValue,
        oddBttsYesValue: this._game.oddBttsYesValue,
        oddBttsNoValue: this._game.oddBttsNoValue
      }
      axiosService.put('/dta/game/basic-data/similar', payload).then(response => {
        this.data = response.data
      })
    },
    _roundProba(proba) {
      return (parseFloat(proba) * 100.0).toFixed(1) + '%'
    },
    _getProbaScoreColorTransparency(proba) {
      let opacity = proba / this._getHighestProbaScore;
      opacity = Math.pow(opacity, 2)
      return `rgba(0, 128, 0, ${opacity})`
    },
    _probaToOdd(proba) {
      if (parseFloat(proba) === 0.0) return '-.--'
      return (1.0 / (proba / 100.0)).toFixed(2)
    },
    _switchOn1X2(value) {
      this.filters.on1X2 = value
      this._refresh()
      preferencesStore().save('SELECTION_SIMILAR_TAB', 'ON_1X2', [this.filters.on1X2])
    },
    _switchOnUnderOver25(value) {
      this.filters.onUnderOver25 = value
      this._refresh()
      preferencesStore().save('SELECTION_SIMILAR_TAB', 'ON_UNDER_OVER_25', [this.filters.onUnderOver25])
    },
    _switchOnBTTS(value) {
      this.filters.onBTTS = value
      this._refresh()
      preferencesStore().save('SELECTION_SIMILAR_TAB', 'ON_BTTS', [this.filters.onBTTS])
    },
    _onSelect1X2Margin(margin) {
      this.filters.on1X2Margin = margin
      this._refresh()
      preferencesStore().save('SELECTION_SIMILAR_TAB', 'ON_1X2_MARGIN', [this.filters.on1X2Margin])
    },
    _onSelectUnderOver25Margin(margin) {
      this.filters.onUnderOver25Margin = margin
      this._refresh()
      preferencesStore().save('SELECTION_SIMILAR_TAB', 'ON_UNDER_OVER_25_MARGIN', [this.filters.onUnderOver25Margin])
    },
    _onSelectBTTSMargin(margin) {
      this.filters.onBTTSMargin = margin
      this._refresh()
      preferencesStore().save('SELECTION_SIMILAR_TAB', 'ON_BTTS_MARGIN', [this.filters.onBTTSMargin])
    },
    _setMoment(moment) {
      this.filters.moment = moment
      this._refresh()
      preferencesStore().save('SELECTION_SIMILAR_TAB', 'MOMENT', [this.filters.moment])
    },
    _switchSettings() {
      this.isSettingsOpened = !this.isSettingsOpened
    },

    _showScoreEvolution(){
      modalStore().openModal({uid: 'scoreEvolutionTreeModal', payload:{ source: 'profiling', gameUID: this.gameUid }})
    }
  }
}
</script>

<template>

  <div v-if="_hasFullLicence">
    <button class="info small" style="width:30px; position: absolute; top: 4px; right: 8px; z-index: 100" @click="_switchSettings">
      <font-awesome-icon icon="fa-solid fa-sliders" v-if="!isSettingsOpened"/>
      <font-awesome-icon icon="fa-solid fa-times" v-else/>
    </button>
    <div class="card mb-1" v-if="isSettingsOpened">
      <div class="card-header">
        Préférences
      </div>
      <div class="card-content text-left pb-2">
        <div>
          <h5>Filtrer sur les côtes 1X2 ?</h5>
          <div class="flex mt-2 pl-1">
            <button :class="{'small translucide ml-1' : true, 'accent' : filters.on1X2 === true}"
                    @click="_switchOn1X2(true)">Oui
            </button>
            <button :class="{'small translucide ml-1' : true, 'accent' : filters.on1X2 === false}"
                    @click="_switchOn1X2(false)">Non
            </button>
          </div>
        </div>
        <div v-if="filters.on1X2">
          <h5>Quelle marge sur les côtes 1X2 ?</h5>
          <div class="flex mt-2 pl-1">
            <button :class="{'small translucide ml-1' : true, 'accent' : filters.on1X2Margin === margin}" v-for="margin in _marginOptions"
                    @click="_onSelect1X2Margin(margin)">{{ (margin * 100.0).toFixed(1) }}%
            </button>
          </div>
        </div>

        <div v-if="_canUseUnderOver25">
          <hr class="mt-2"/>
          <div>
            <h5>Filtrer sur les côtes Under/Over ?</h5>
            <div class="flex mt-2 pl-1">
              <button :class="{'small translucide ml-1' : true, 'accent' : filters.onUnderOver25 === true}"
                      @click="_switchOnUnderOver25(true)">Oui
              </button>
              <button :class="{'small translucide ml-1' : true, 'accent' : filters.onUnderOver25 === false}"
                      @click="_switchOnUnderOver25(false)">Non
              </button>
            </div>
          </div>
          <div v-if="filters.onUnderOver25">
            <h5>Quelle marge sur les côtes Under/Over ?</h5>
            <div class="flex mt-2 pl-1">
              <button :class="{'small translucide ml-1' : true, 'accent' : filters.onUnderOver25Margin === margin}" v-for="margin in _marginOptions"
                      @click="_onSelectUnderOver25Margin(margin)">{{ (margin * 100.0).toFixed(1) }}%
              </button>
            </div>
          </div>
        </div>

        <div v-if="_canUseBTTS">
          <hr class="mt-2"/>
          <div>
            <h5>Filtrer sur les côtes BTTS ?</h5>
            <div class="flex mt-2 pl-1">
              <button :class="{'small translucide ml-1' : true, 'accent' : filters.onBTTS === true}"
                      @click="_switchOnBTTS(true)">Oui
              </button>
              <button :class="{'small translucide ml-1' : true, 'accent' : filters.onBTTS === false}"
                      @click="_switchOnBTTS(false)">Non
              </button>
            </div>
          </div>
          <div v-if="filters.onBTTS">
            <h5>Quelle marge sur les côtes BTTS ?</h5>
            <div class="flex mt-2 pl-1">
              <button :class="{'small translucide ml-1' : true, 'accent' : filters.onBTTSMargin === margin}" v-for="margin in _marginOptions"
                      @click="_onSelectBTTSMargin(margin)">{{ (margin * 100.0).toFixed(1) }}%
              </button>
            </div>
          </div>
        </div>

        <a-alert v-if="!filters.on1X2 && (!_canUseUnderOver25 || !filters.onUnderOver25) && (!_canUseBTTS || !filters.onBTTS)" class="mx-1 mt-2"
                 message="Au moins un critère côte doit être actif." type="error" show-icon/>

        <div v-else>
          <hr class="mt-2"/>
          <div>
            <h5>Quel moment du match t'intéresse ?</h5>
            <div class="flex mt-2 pl-1">
              <button class="small translucide ml-1" :class="{'accent' : filters.moment === 'FT'}" @click="_setMoment('FT')">FT</button>
              <button class="small translucide ml-1" :class="{'accent' : filters.moment === 'P1'}" @click="_setMoment('P1')">P1</button>
              <button class="small translucide ml-1" :class="{'accent' : filters.moment === 'P2'}" @click="_setMoment('P2')">P2</button>
            </div>
          </div>
        </div>

        <hr class="mt-2"/>
        <p class="mx-2 mt-2 italic opacity-70">
          Les marges sont appliquées sur les probabilités (hors TRJ). Par exemple, si une côte à 3.90 a une probabilité de 26%, on retiendra les matchs où la probabilité est entre 23.5% et 28.5% si la marge 2.5% est utilisée.
        </p>
        <p class="mx-2 mt-2 italic opacity-70">
          Plus la marge utilisée est grande, plus le nombre de matchs similaires sera important. Mais la qualité des matchs similaires sera moins bonne car moins proche des côtes du match de référence.
        </p>
      </div>
    </div>

    <div v-if="_dataValid" class="text-left">
      <div class="card mb-1">
        <div class="card-header">
          Résultat (1X2) {{filters.moment}}
        </div>
        <div class="card-content text-left p-1">
          <div class="flex">
            <button class="stats-merge-item-button flex-grow">
              <span class="stats-merge-item-button-label">1</span>
              <span class="stats-merge-item-button-value">
                <span>{{ _homeWinRate }}% </span>
                <span class="stats-merge-item-button-sub-value"><sup>{{ _probaToOdd(_homeWinRate) }}</sup></span>
              </span>
            </button>
            <button class="stats-merge-item-button flex-grow mx-1">
              <span class="stats-merge-item-button-label">N</span>
              <span class="stats-merge-item-button-value">
                <span>{{ _drawRate }}% </span>
                <span class="stats-merge-item-button-sub-value"><sup>{{ _probaToOdd(_drawRate) }}</sup></span>
              </span>
            </button>
            <button class="stats-merge-item-button flex-grow">
              <span class="stats-merge-item-button-label">2</span>
              <span class="stats-merge-item-button-value">
                <span>{{ _awayWinRate }}% </span>
                <span class="stats-merge-item-button-sub-value"><sup>{{ _probaToOdd(_awayWinRate) }}</sup></span>
              </span>
            </button>
          </div>
        </div>
      </div>

      <div class="card mb-1">
        <div class="card-content text-left p-1">
          <div class="flex">
            <button class="stats-merge-item-button flex-grow">
              <span class="stats-merge-item-button-label">1 ou X</span>
              <span class="stats-merge-item-button-value">
                <span>{{ 100 - _awayWinRate }}% </span>
                <span class="stats-merge-item-button-sub-value"><sup>{{ _probaToOdd(100 - _awayWinRate) }}</sup></span>
              </span>
            </button>
            <button class="stats-merge-item-button flex-grow mx-1">
              <span class="stats-merge-item-button-label">1 ou 2</span>
              <span class="stats-merge-item-button-value">
                <span>{{ 100 - _drawRate }}% </span>
                <span class="stats-merge-item-button-sub-value"><sup>{{ _probaToOdd(100 - _drawRate) }}</sup></span>
              </span>
            </button>
            <button class="stats-merge-item-button flex-grow">
              <span class="stats-merge-item-button-label">X ou 2</span>
              <span class="stats-merge-item-button-value">
                <span>{{ 100 - _homeWinRate }}% </span>
                <span class="stats-merge-item-button-sub-value"><sup>{{ _probaToOdd(100 - _homeWinRate) }}</sup></span>
              </span>
            </button>
          </div>
        </div>
      </div>

      <div class="card mb-1" v-if="data && _shouldShowBtts">
        <div class="card-header">
          Les 2 équipes marquent (BTTS) {{filters.moment}}
        </div>
        <div class="card-content text-left p-1">
          <div class="flex">
            <button class="stats-merge-item-button flex-grow">
              <span class="stats-merge-item-button-label">Oui</span>
              <span class="stats-merge-item-button-value">
                <span>{{ _bttsRate }}% </span>
                <span class="stats-merge-item-button-sub-value"><sup>{{ _probaToOdd(_bttsRate) }}</sup></span>
              </span>
            </button>
            <button class="stats-merge-item-button flex-grow ml-1">
              <span class="stats-merge-item-button-label">Non</span>
              <span class="stats-merge-item-button-value">
                <span>{{ 100 - _bttsRate }}% </span>
                <span class="stats-merge-item-button-sub-value"><sup>{{ _probaToOdd(100 - _bttsRate) }}</sup></span>
              </span>
            </button>
          </div>
        </div>
      </div>

      <div class="card mb-1" v-if="data && _shouldShowUnderOver">
        <div class="card-header">
          Under / Over {{filters.moment}}
        </div>
        <div class="card-content text-left p-1">
          <div class="flex">
            <button class="stats-merge-item-button flex-grow" v-if="_shouldShowUnderOver05">
              <span class="stats-merge-item-button-label">+0.5</span>
              <span class="stats-merge-item-button-value">
                <span>{{ _over05Rate }}% </span>
                <span class="stats-merge-item-button-sub-value"><sup>{{ _probaToOdd(_over05Rate) }}</sup></span>
              </span>
            </button>
            <button class="stats-merge-item-button flex-grow ml-1" v-if="_shouldShowUnderOver15">
              <span class="stats-merge-item-button-label">+1.5</span>
              <span class="stats-merge-item-button-value">
                <span>{{ _over15Rate }}% </span>
                <span class="stats-merge-item-button-sub-value"><sup>{{ _probaToOdd(_over15Rate) }}</sup></span>
              </span>
            </button>
            <button class="stats-merge-item-button flex-grow ml-1" v-if="_shouldShowUnderOver25">
              <span class="stats-merge-item-button-label">+2.5</span>
              <span class="stats-merge-item-button-value">
                <span>{{ _over25Rate }}% </span>
                <span class="stats-merge-item-button-sub-value"><sup>{{ _probaToOdd(_over25Rate) }}</sup></span>
              </span>
            </button>
            <button class="stats-merge-item-button flex-grow ml-1" v-if="_shouldShowUnderOver35">
              <span class="stats-merge-item-button-label">+3.5</span>
              <span class="stats-merge-item-button-value">
                <span>{{ _over35Rate }}% </span>
                <span class="stats-merge-item-button-sub-value"><sup>{{ _probaToOdd(_over35Rate) }}</sup></span>
              </span>
            </button>
            <button class="stats-merge-item-button flex-grow ml-1" v-if="_shouldShowUnderOver45">
              <span class="stats-merge-item-button-label">+4.5</span>
              <span class="stats-merge-item-button-value">
                <span>{{ _over45Rate }}% </span>
                <span class="stats-merge-item-button-sub-value"><sup>{{ _probaToOdd(_over45Rate) }}</sup></span>
              </span>
            </button>
            <button class="stats-merge-item-button flex-grow ml-1" v-if="_shouldShowUnderOver55">
              <span class="stats-merge-item-button-label">+5.5</span>
              <span class="stats-merge-item-button-value">
                <span>{{ _over55Rate }}% </span>
                <span class="stats-merge-item-button-sub-value"><sup>{{ _probaToOdd(_over55Rate) }}</sup></span>
              </span>
            </button>
            <button class="stats-merge-item-button flex-grow ml-1" v-if="_shouldShowUnderOver65">
              <span class="stats-merge-item-button-label">+6.5</span>
              <span class="stats-merge-item-button-value">
                <span>{{ _over65Rate }}% </span>
                <span class="stats-merge-item-button-sub-value"><sup>{{ _probaToOdd(_over65Rate) }}</sup></span>
              </span>
            </button>
          </div>
        </div>
      </div>

      <div class="card mb-1" v-if="data && _shouldShowUnderOver">
        <div class="card-content text-left p-1">
          <div class="flex">
            <button class="stats-merge-item-button flex-grow" v-if="_shouldShowUnderOver05">
              <span class="stats-merge-item-button-label">-0.5</span>
              <span class="stats-merge-item-button-value">
                <span>{{ 100 - _over05Rate }}% </span>
                <span class="stats-merge-item-button-sub-value"><sup>{{ _probaToOdd(100 - _over05Rate) }}</sup></span>
              </span>
            </button>
            <button class="stats-merge-item-button flex-grow ml-1" v-if="_shouldShowUnderOver15">
              <span class="stats-merge-item-button-label">-1.5</span>
              <span class="stats-merge-item-button-value">
                <span>{{ 100 - _over15Rate }}% </span>
                <span class="stats-merge-item-button-sub-value"><sup>{{ _probaToOdd(100 - _over15Rate) }}</sup></span>
              </span>
            </button>
            <button class="stats-merge-item-button flex-grow ml-1" v-if="_shouldShowUnderOver25">
              <span class="stats-merge-item-button-label">-2.5</span>
              <span class="stats-merge-item-button-value">
                <span>{{ 100 - _over25Rate }}% </span>
                <span class="stats-merge-item-button-sub-value"><sup>{{ _probaToOdd(100 - _over25Rate) }}</sup></span>
              </span>
            </button>
            <button class="stats-merge-item-button flex-grow ml-1" v-if="_shouldShowUnderOver35">
              <span class="stats-merge-item-button-label">-3.5</span>
              <span class="stats-merge-item-button-value">
                <span>{{ 100 - _over35Rate }}% </span>
                <span class="stats-merge-item-button-sub-value"><sup>{{ _probaToOdd(100 - _over35Rate) }}</sup></span>
              </span>
            </button>
            <button class="stats-merge-item-button flex-grow ml-1" v-if="_shouldShowUnderOver45">
              <span class="stats-merge-item-button-label">-4.5</span>
              <span class="stats-merge-item-button-value">
                <span>{{ 100 - _over45Rate }}% </span>
                <span class="stats-merge-item-button-sub-value"><sup>{{ _probaToOdd(100 - _over45Rate) }}</sup></span>
              </span>
            </button>
            <button class="stats-merge-item-button flex-grow ml-1" v-if="_shouldShowUnderOver55">
              <span class="stats-merge-item-button-label">-5.5</span>
              <span class="stats-merge-item-button-value">
                <span>{{ 100 - _over55Rate }}% </span>
                <span class="stats-merge-item-button-sub-value"><sup>{{ _probaToOdd(100 - _over55Rate) }}</sup></span>
              </span>
            </button>
            <button class="stats-merge-item-button flex-grow ml-1" v-if="_shouldShowUnderOver65">
              <span class="stats-merge-item-button-label">-6.5</span>
              <span class="stats-merge-item-button-value">
                <span>{{ 100 - _over65Rate }}% </span>
                <span class="stats-merge-item-button-sub-value"><sup>{{ _probaToOdd(100 - _over65Rate) }}</sup></span>
              </span>
            </button>
          </div>
        </div>
      </div>

      <div class="card mb-1" v-if="data && _shouldShowUnderOver">
        <div class="card-header flex">
          <div class="flex-grow">
            Les scores les plus fréquents {{filters.moment}}
          </div>
          <!--<button class="small info" @click="_showScoreEvolution">
            Evolution
          </button>-->
        </div>
        <div class="card-content text-left">
          <a-button-group v-for="score in _topScoresRate" class="m-1 inline-block">
            <button class="info no-radius-right">
              {{ score }}
            </button>
            <button class="no-radius-left"
                    :style="{'backgroundColor': _getProbaScoreColorTransparency(_scoreRates[score])}">
              {{ _roundProba(_scoreRates[score]) }}
            </button>
          </a-button-group>
        </div>
      </div>

      <a-alert class="m-2" :message="_gameFoundMessages" :type="_gameFoundType" show-icon/>

      <hr/>
      <div class="flex p-2">
        <p class="pr-2 m-0 flex-grow">🔍 Avec cet onglet, découvre l'historique des résultats de matchs aux profils de
          cotes similaires ! 📊 Analyse poussée = décisions plus éclairées ! 💡</p>
        <youtube-button video-id="PSI-QYT6NcI"/>
      </div>
    </div>
    <a-alert v-else-if="!data" message="Chargement..." type="info" class="m-2"/>
    <a-alert v-else type="error" class="m-2" show-icon
             message="Aucune donnée disponible ne correspondant à la demande."/>
  </div>
  <div v-else>
    <a-alert
        message="Il faut une licence ULTIMATE pour utiliser l'analyse par profil de côte."
        type="info" show-icon class="m-1"/>
  </div>

</template>

<style scoped lang="less">
@import "@/assets/styles/variables.less";

.filters {
  background: @background-color-light;
  padding: @padding-small;
}

fieldset {
  background: @background-color;
}

</style>
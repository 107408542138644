<script>

export default {
  name: "PrivacyRoute",
  components: {

  },

  computed: {

  },
}
</script>

<template>
  <div class="privacy-page">
    <h1>Politique de Confidentialité de DoctoBET</h1>
    <p class="update-date">Dernière mise à jour : 08 mars 2025</p>

    <div class="introduction">
      <p>Merci d'utiliser DoctoBET ! J'accorde une grande importance à la protection de vos données personnelles.
        Cette politique de confidentialité explique quelles informations je collecte, comment je les utilise et quels sont vos droits.</p>
    </div>

    <div class="section">
      <h2 class="section-title">1. Informations collectées</h2>
      <div class="section-content">
        <p>Je collecte les données suivantes lorsque vous utilisez mon application :</p>
        <ul>
          <li>Nom et Prénom</li>
          <li>Adresse e-mail</li>
          <li>Marque et modèle de votre smartphone</li>
        </ul>
        <p>Aucun cookie ni donnée sensible (ex. : localisation précise, données de santé) ne sont collectés.</p>
      </div>
    </div>

    <!-- Répéter pour les sections 2 à 6 -->
    <div class="section">
      <h2 class="section-title">2. Utilisation des données</h2>
      <div class="section-content">
        <p>Vos données personnelles sont collectées uniquement pour :</p>
        <ul>
          <li>Vous identifier lors de l'utilisation de DoctoBET</li>
          <li>Faciliter le support utilisateur en cas de problème technique</li>
        </ul>
        <p>Je ne partage aucune de vos données avec aucun tiers.</p>
      </div>
    </div>

    <div class="section">
      <h2 class="section-title">6. Contact</h2>
      <div class="section-content">
        <p>Si vous avez des questions concernant cette politique de confidentialité, vous pouvez me contacter :</p>
        <div class="contact-info">
          <p>Par e-mail : <a href="mailto:contact@docto.bet">contact@docto.bet</a></p>
          <p>Sur Discord : DoctoBET</p>
          <p>Sur X.com (Twitter) : @OctobetTeam</p>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="less">
@import "@/assets/styles/variables.less";

.privacy-page {
  max-width: 800px;
  margin: 0 auto;
  padding: 40px 20px;
  color: #333;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, sans-serif;
  line-height: 1.6;
  text-align: left !important;
  height: calc(100vh);
  overflow: auto;
  scrollbar-width: none;

  h1 {
    font-size: 28px;
    font-weight: 600;
    margin-bottom: 20px;
    color: @primary-color;

  }

  .update-date {
    font-size: 14px;
    color: @text-color;
    margin-bottom: 30px;

    font-style: italic;
  }

  .introduction {
    margin-bottom: 30px;
    font-size: 16px;
  }

  .section {
    margin-bottom: 30px;
    text-align: left !important;
    &-title {
      text-align: left !important;
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 15px;
      color: @primary-color;
    }

    &-content {
      text-align: left !important;

      p {
        margin-bottom: 10px;
      }

      ul {
        list-style-type: disc;
        padding-left: 20px;
        margin-bottom: 15px;

        li {
          color: @text-color;
          margin-bottom: 5px;
        }
      }
    }
  }

  .contact-info {
    margin-top: 30px;
    padding: 15px;
    background-color: #00000033;
    border-radius: 8px;

    p {
      margin-bottom: 5px;
    }
  }

  @media (max-width: 768px) {
    padding: 20px 15px;

    h1 {
      font-size: 24px;
    }

    .section-title {
      font-size: 18px;
    }
  }
}
</style>
// stores/counter.js
import { defineStore } from 'pinia'

import axiosService from "@/services/axios.service";
import { contextStore } from "@/stores/Context.store";
import { offerStore } from "@/stores/Offer.store";

export const selectionStore = defineStore('selectionStore', {
  state: () => {
    return {
      items: null
    }
  },
  actions: {

    createSelection(name, type, sport, callback) {
      const payload = {
        sport: sport,
        type: type,
        name: name
      }
      axiosService.post('/sel/selection', payload).then(response => {
        const ss = selectionStore();
        let selection = response.data.result;
        ss.add(selection)

        if (callback) {
          callback(selection)
        }
      });
    },

    /**
     * Permet l'initialisation des selections
     * @param callback
     */
    fetchAll(callback = null) {
      if (this.items !== null) {
        if (callback) callback()
        return
      }
      this.items = []

      axiosService.get('/sel/selection').then(response => {
        this.items = this.items.concat(response.data.results)
      }).catch(error => {
        console.error('selectionStore.fetchAll', error)
      }).finally(() => {
        if (callback) callback()
      })
    },

    add(selection) {
      if (!this.items) {
        this.items = []
      }
      // si la sélection est déjà présente, on ne l'ajoute pas
      if (this.items.find(item => item.uid === selection.uid)) {
        return
      }
      this.items.push(selection)
    },
    addAll(selections) {
      selections.forEach(selection => {
        this.add(selection)
      })
    },

    /**
     * Permet de sauvegarder une selection en base de données sur le serveur
     * @param selection
     */
    saveSelection(selection) {
      const payload = {
        action: 'save',
        selection: selection
      }
      axiosService.put('/sel/selection', payload)
        .then(response => {
          // Selection sauvegardée.
        })
        .catch(error => {
          console.error('selectionStore.saveSelection', error)
        })
    },

    deleteSelection(selection) {
      axiosService.delete('/sel/selection/' + selection.uid)
        .then(response => {
          this.items = this.items.filter(item => item.uid !== selection.uid)
        })
        .catch(error => {
          console.error('selectionStore.deleteSelection', error)
        })
    },

    duplicateSelection(selection) {
      axiosService.post('/sel/selection/' + selection.uid + '/duplicate')
        .then(response => {
          const ss = selectionStore();
          let selection = response.data.result;
          ss.add(selection)
        })
        .catch(error => {
          console.error('selectionStore.deleteSelection', error)
        })
    },

    removeUselessOperands(selection) {
      if (!selection) return
      if (selection.operation) {
        this.removeUselessOperandsInOperation(selection.operation)
      }
    },

    removeUselessOperandsInOperation(operation) {
      if (!operation) return
      if (operation.operations) {
        operation.operations.forEach(op => {
          this.removeUselessOperandsInOperation(op)
        })
      }

      if (!operation.operands) {
        return
      }

      operation.operands = operation.operands.filter(operand => {
        return operand.targetFixedUID !== null
      })
    },

    updateOperand(newValue) {

      this.items.forEach(selection => {

        if (!selection.operation) {
          return
        }

        const isSameSelection = selection.operation.uid === newValue.rootOperationUID
        if (!isSameSelection) {
          return
        }

        if (selection.operation) {
          this.updateOperandInOperation(selection.operation, newValue)
        }

        if (!selection.operands) {
          return
        }

        let operandFound = false
        selection.operands.forEach(operand => {
          let isSameUID = (operand.uid || newValue.uid) && operand.uid === newValue.uid;
          let isSameTempUID = (operand.tempUID || newValue.tempUID) && operand.tempUID === newValue.tempUID;
          if (isSameUID || isSameTempUID) {
            operandFound = true
            operand.targetType = newValue.targetType
            operand.targetFixedUID = newValue.targetFixedUID
            operand.argument = newValue.argument
            operand.player = newValue.player
            operand.who = newValue.who
            operand.against = newValue.against
            operand.period = newValue.period
            operand.location = newValue.location
            operand.league = newValue.league
            operand.matchingScoreNegativeImpact = newValue.matchingScoreNegativeImpact
            operand.condition.operator = newValue.condition.operator
            operand.condition.value = newValue.condition.value
            operand.condition.valueAbsolute = newValue.condition.valueAbsolute
            operand.condition.valuableIndicator = newValue.condition.valuableIndicator
            operand.condition.bookmakerUID = newValue.condition.bookmakerUID

            // Comparaison de cote
            operand.condition.comparedToOperator = newValue.condition.comparedToOperator
            operand.condition.comparedToFixedUID = newValue.condition.comparedToFixedUID
            operand.condition.comparedToArgument = newValue.condition.comparedToArgument
            operand.condition.comparedToEcart = newValue.condition.comparedToEcart

          }
        })

        if (!operandFound) {
          console.log('operand not found', operandUID)
        }
      })
    },

    updateOperandInOperation(operation, newValue) {

      if (operation.operations) {
        operation.operations.forEach(op => {
          this.updateOperandInOperation(op, newValue)
        })
      }

      if (!operation.operands) {
        return
      }

      let operandFound = false
      operation.operands.forEach(operand => {
        let isSameUID = (operand.uid || newValue.uid) && operand.uid === newValue.uid;
        let isSameTempUID = (operand.tempUID || newValue.tempUID) && operand.tempUID === newValue.tempUID;
        if (isSameUID || isSameTempUID) {
          operandFound = true
          operand.targetType = newValue.targetType
          operand.targetFixedUID = newValue.targetFixedUID
          operand.argument = newValue.argument
          operand.player = newValue.player
          operand.who = newValue.who
          operand.against = newValue.against
          operand.period = newValue.period
          operand.location = newValue.location
          operand.league = newValue.league
          operand.matchingScoreNegativeImpact = newValue.matchingScoreNegativeImpact
          operand.condition.operator = newValue.condition.operator
          operand.condition.value = newValue.condition.value
          operand.condition.valueAbsolute = newValue.condition.valueAbsolute
          operand.condition.valuableIndicator = newValue.condition.valuableIndicator
          operand.condition.bookmakerUID = newValue.condition.bookmakerUID
          // Comparaison de cote
          operand.condition.comparedToOperator = newValue.condition.comparedToOperator
          operand.condition.comparedToFixedUID = newValue.condition.comparedToFixedUID
          operand.condition.comparedToArgument = newValue.condition.comparedToArgument
          operand.condition.comparedToEcart = newValue.condition.comparedToEcart
        }
      })
      if (!operandFound) {
        console.warn('operand not found', newValue, operation)
      }
    },
  },
  getters: {
    get: (state) => (uid) => {

      if (uid == null) {
        return null
      }

      if (state.items === null) {
        return null
      }

      const uidInt = parseInt(uid)

      let item = state.items.find(item => item.uid === uidInt);
      if (item) {
        return item
      }

      return null
    },

    getAllByUIDs: (state) => (uids) => {
      if (state.items === null) {
        return []
      }

      let items = state.items.filter(item => uids.includes(item.uid));
      if (items) {
        return items
      }

      return []
    },

    getAll: (state) => {
      if (state.items === null) {
        console.warn("selectionStore.get", "items is null")
        return null
      }

      return state.items
    },

    getUserSelections: (state) => (types = []) => {
      if (state.items === null) {
        console.warn("selectionStore.get", "items is null")
        return null
      }

      if (!types || types.length === 0) {
        console.warn("Types is empty or null. Cannot get user selections")
        return null
      }

      const cs = contextStore()
      if (!cs.loggedUser) {
        return []
      }

      return state.items.filter(item => item.userUID === cs.loggedUser.uid && types.indexOf(item.type) !== -1)
    },

    getDefaultSelections: (state) => (types = []) => {
      if (state.items === null) {
        console.warn("selectionStore.get", "items is null")
        return null
      }

      if (!types || types.length === 0) {
        console.warn("Types is empty or null. Cannot get default selections")
        return null
      }

      return state.items.filter(item => item.userUID === null && types.indexOf(item.type) !== -1)
    },

    isYours: (state) => (uid) => {
      const selection = state.items.find(item => item.uid === uid)
      if (!selection) {
        return false
      }
      const cs = contextStore()
      if (!cs.loggedUser) {
        return false
      }
      const isYours = selection.userUID === cs.loggedUser.uid
      if (!isYours) {
        return false
      }
      return selection
    },

    getAllOperands: (state) => (operation) => {
      const results = []
      if (!operation) {
        return results
      }
      if (!operation.operands) {
        return results
      }
      operation.operands.forEach(operand => {
        results.push(operand)
      })
      if (operation.operations) {
        operation.operations.forEach(op => {
          const operands = selectionStore().getAllOperands(op)
          operands.forEach(operand => {
            results.push(operand)
          })
        })
      }

      return results
    },

    getOperandLabel: (state) => (operand, options = {}) => {
      const ss = selectionStore()
      switch (operand.targetType) {
        case 'STATISTIC_DEFINITION':
          return operand.argument.name
        case 'SELECTION':
          return operand.argument.name
        case 'ODD_PROFILING':
          return ss._getOddProfilingLabel(operand, options.selection)
      }
    },

    _getOddProfilingLabel: (state) => (operand, selection) => {

      let offerFamilyChoice = offerStore().getOfferFamilyChoiceBySportAndFixedUID(selection.sport, operand.targetFixedUID)

      if (!offerFamilyChoice) {
        console.error("offerFamilyChoice are required", offerFamilyChoice)
        return "..."
      }

      if (!operand) {
        console.error("operand is required", operand)
        return "..."
      }

      let os = offerStore();
      let base = os.getOfferFamilyChoiceLabelByUID(operand.targetFixedUID, operand.argument, operand.condition.bookmakerUID)
      switch (operand.condition.operator) {
        case 'UPPER':
          base += "dans + de "
          break
        case 'LOWER':
          base += "dans - de "
          break
      }
      if (operand.condition.value) {
        base += (operand.condition.value * 100.0).toFixed(1) + '%'
      } else {
        base += "--.-%"
      }

      base += ' des cas.'

      return 'Profilage: ' + base
    }
  }
})
<script>
import MainHeader from "@/components/common/MainHeader.vue";
import {contextStore} from "@/stores/Context.store";
import {userStore} from "@/stores/User.store";
import UserPreferencesModal from "@/components/common/modal/user_preferences/UserPreferencesModal.vue";
import CalculatorModal from "@/components/common/modal/calculator/CalculatorModal.vue";
import LicenceLeagueModal from "@/components/common/modal/LicenceLeagueModal.vue";
import LeagueLogo from "@/components/common/LeagueLogo.vue";
import BlogSeeMore from "@/components/routes/blog/BlogSeeMore.vue";
import {preferencesStore} from "@/stores/Preferences.store";
import {blogRouteStore} from "@/components/routes/blog/BlogRoute.store";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import YoutubeButton from "@/components/common/YoutubeButton.vue";

export default {
  name: "MainRoute",
  components: {
    YoutubeButton,
    FontAwesomeIcon,
    BlogSeeMore,
    LeagueLogo,
    LicenceLeagueModal,
    CalculatorModal,
    UserPreferencesModal,
    MainHeader
  },
  computed: {
    _isMobile() {
      return contextStore().isMobileDevice()
    },
    _randomAvis() {
      return blogRouteStore().getAvis(3)
    }
  },
  methods: {
    _signWithGoogle() {
      userStore().showSignInModal()
    },
    _openYoutube(videoId) {
      window.open(`https://www.youtube.com/watch?v=${videoId}`, '_blank')
    },
    _goToStats() {
      preferencesStore().save('DEFAULT_ROUTE', 'INIT_ROUTE', ['stats'],
          () => {
            this.$router.push({name: 'stats'})
          }
      )
    },
    _goToGames() {
      preferencesStore().save('DEFAULT_ROUTE', 'INIT_ROUTE', ['games'],
          () => {
            this.$router.push({name: 'games'})
          }
      )
    },
    _goToPlayers() {
      preferencesStore().save('DEFAULT_ROUTE', 'INIT_ROUTE', ['players'],
          () => {
            this.$router.push({name: 'players'})
          }
      )
    },
    _continue() {
      preferencesStore().save('DEFAULT_ROUTE', 'INIT_ROUTE', ['stats'],
          () => {
              this._goToStats()
          }
      )
    },
    _scrollToOffers() {
      document.getElementById('offers-wrapper').scrollIntoView({behavior: 'smooth'})
    },
    _firstLetter(avis) {
      return avis.name.charAt(0).toUpperCase()
    },
    _avisColor(index) {
      switch (index % 4) {
        case 0:
          return {background: '#6DFFC2'}
        case 1:
          return {background: '#FFA500'}
        case 2:
          return {background: '#FF6347'}
      }
    }
  },
  beforeMount() {
    if (contextStore().shouldReload()) {
      window.location.href = window.location.origin
    }

    if (!contextStore().isInitialized()) {
      this.$router.push({name: 'init', query: {redirect: 'main', query: this._urlUID}})
    }
  },
}
</script>

<template>
  <div :class="{'mobile': _isMobile}">
    <main-header/>

    <div class="main-welcome-pane">

      <div class="w-100 space-x-8 h-16">
        <a href="https://www.twitter.com/OctobetTeam" target="_blank">
          <svg class="svg-inline--fa fa-twitter text-4xl p-2 white hover:text-5xl hover:p-0 duration-200"
               aria-hidden="true" focusable="false" data-prefix="fab" data-icon="twitter" role="img"
               xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--!Font Awesome Free 6.7.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2025 Fonticons, Inc.-->
            <path class="" fill="currentColor" d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z"/>
          </svg>
        </a>
        <a href="https://bsky.app/profile/doctobet.bsky.social" target="_blank">
          <svg class="svg-inline--fa fa-bluesky text-4xl p-2 white hover:text-5xl hover:p-0 duration-200"
               aria-hidden="true" focusable="false" data-prefix="fab" data-icon="bsky" role="img"
               xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
            <path class="" fill="currentColor"
                  d="M111.8 62.2C170.2 105.9 233 194.7 256 242.4c23-47.6 85.8-136.4 144.2-180.2c42.1-31.6 110.3-56 110.3 21.8c0 15.5-8.9 130.5-14.1 149.2C478.2 298 412 314.6 353.1 304.5c102.9 17.5 129.1 75.5 72.5 133.5c-107.4 110.2-154.3-27.6-166.3-62.9l0 0c-1.7-4.9-2.6-7.8-3.3-7.8s-1.6 3-3.3 7.8l0 0c-12 35.3-59 173.1-166.3 62.9c-56.5-58-30.4-116 72.5-133.5C100 314.6 33.8 298 15.7 233.1C10.4 214.4 1.5 99.4 1.5 83.9c0-77.8 68.2-53.4 110.3-21.8z"/>
          </svg>
        </a>
        <a href="https://www.threads.net/@docto.bet" target="_blank">
          <svg class="svg-inline--fa fa-threads text-4xl p-2 white hover:text-5xl hover:p-0 duration-200"
               aria-hidden="true" focusable="false" data-prefix="fab" data-icon="threads" role="img"
               xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
            <path class="" fill="currentColor"
                d="M331.5 235.7c2.2 .9 4.2 1.9 6.3 2.8c29.2 14.1 50.6 35.2 61.8 61.4c15.7 36.5 17.2 95.8-30.3 143.2c-36.2 36.2-80.3 52.5-142.6 53h-.3c-70.2-.5-124.1-24.1-160.4-70.2c-32.3-41-48.9-98.1-49.5-169.6V256v-.2C17 184.3 33.6 127.2 65.9 86.2C102.2 40.1 156.2 16.5 226.4 16h.3c70.3 .5 124.9 24 162.3 69.9c18.4 22.7 32 50 40.6 81.7l-40.4 10.8c-7.1-25.8-17.8-47.8-32.2-65.4c-29.2-35.8-73-54.2-130.5-54.6c-57 .5-100.1 18.8-128.2 54.4C72.1 146.1 58.5 194.3 58 256c.5 61.7 14.1 109.9 40.3 143.3c28 35.6 71.2 53.9 128.2 54.4c51.4-.4 85.4-12.6 113.7-40.9c32.3-32.2 31.7-71.8 21.4-95.9c-6.1-14.2-17.1-26-31.9-34.9c-3.7 26.9-11.8 48.3-24.7 64.8c-17.1 21.8-41.4 33.6-72.7 35.3c-23.6 1.3-46.3-4.4-63.9-16c-20.8-13.8-33-34.8-34.3-59.3c-2.5-48.3 35.7-83 95.2-86.4c21.1-1.2 40.9-.3 59.2 2.8c-2.4-14.8-7.3-26.6-14.6-35.2c-10-11.7-25.6-17.7-46.2-17.8H227c-16.6 0-39 4.6-53.3 26.3l-34.4-23.6c19.2-29.1 50.3-45.1 87.8-45.1h.8c62.6 .4 99.9 39.5 103.7 107.7l-.2 .2zm-156 68.8c1.3 25.1 28.4 36.8 54.6 35.3c25.6-1.4 54.6-11.4 59.5-73.2c-13.2-2.9-27.8-4.4-43.4-4.4c-4.8 0-9.6 .1-14.4 .4c-42.9 2.4-57.2 23.2-56.2 41.8l-.1 .1z"/>
          </svg>
        </a>

        <a href="https://discord.gg/t2yYqYxaNr" target="_blank">
          <svg class="svg-inline--fa fa-discord text-4xl p-2 white hover:text-5xl hover:p-0 duration-200"
               aria-hidden="true" focusable="false" data-prefix="fab" data-icon="discord" role="img"
               xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
            <path class="" fill="currentColor"
                  d="M524.531,69.836a1.5,1.5,0,0,0-.764-.7A485.065,485.065,0,0,0,404.081,32.03a1.816,1.816,0,0,0-1.923.91,337.461,337.461,0,0,0-14.9,30.6,447.848,447.848,0,0,0-134.426,0,309.541,309.541,0,0,0-15.135-30.6,1.89,1.89,0,0,0-1.924-.91A483.689,483.689,0,0,0,116.085,69.137a1.712,1.712,0,0,0-.788.676C39.068,183.651,18.186,294.69,28.43,404.354a2.016,2.016,0,0,0,.765,1.375A487.666,487.666,0,0,0,176.02,479.918a1.9,1.9,0,0,0,2.063-.676A348.2,348.2,0,0,0,208.12,430.4a1.86,1.86,0,0,0-1.019-2.588,321.173,321.173,0,0,1-45.868-21.853,1.885,1.885,0,0,1-.185-3.126c3.082-2.309,6.166-4.711,9.109-7.137a1.819,1.819,0,0,1,1.9-.256c96.229,43.917,200.41,43.917,295.5,0a1.812,1.812,0,0,1,1.924.233c2.944,2.426,6.027,4.851,9.132,7.16a1.884,1.884,0,0,1-.162,3.126,301.407,301.407,0,0,1-45.89,21.83,1.875,1.875,0,0,0-1,2.611,391.055,391.055,0,0,0,30.014,48.815,1.864,1.864,0,0,0,2.063.7A486.048,486.048,0,0,0,610.7,405.729a1.882,1.882,0,0,0,.765-1.352C623.729,277.594,590.933,167.465,524.531,69.836ZM222.491,337.58c-28.972,0-52.844-26.587-52.844-59.239S193.056,219.1,222.491,219.1c29.665,0,53.306,26.82,52.843,59.239C275.334,310.993,251.924,337.58,222.491,337.58Zm195.38,0c-28.971,0-52.843-26.587-52.843-59.239S388.437,219.1,417.871,219.1c29.667,0,53.307,26.82,52.844,59.239C470.715,310.993,447.538,337.58,417.871,337.58Z"></path>
          </svg>
        </a><a href="mailto:contact@Docto.BET?subject=Contact" target="_blank">
        <svg class="svg-inline--fa fa-envelope text-4xl p-2 white hover:text-5xl hover:p-0 duration-200"
             aria-hidden="true" focusable="false" data-prefix="fas" data-icon="envelope" role="img"
             xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
          <path class="" fill="currentColor"
                d="M48 64C21.5 64 0 85.5 0 112c0 15.1 7.1 29.3 19.2 38.4L236.8 313.6c11.4 8.5 27 8.5 38.4 0L492.8 150.4c12.1-9.1 19.2-23.3 19.2-38.4c0-26.5-21.5-48-48-48H48zM0 176V384c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V176L294.4 339.2c-22.8 17.1-54 17.1-76.8 0L0 176z"></path>
        </svg>
      </a></div>
      <div class="bloc" style="text-align: center; height: 100vh; padding-top: 8vh">
        <h1 class="lg:w-1/2 md:w-2/3 m-auto">
          DOCTO<span class="highlighted">BET</span>
        </h1>
        <h4 class="lg:w-1/2 md:w-2/3 m-auto my-5">Avec nos stats, deviens un pro des paris sportifs</h4>
        <h3 class="lg:w-1/2 md:w-2/3 m-auto">
          DoctoBET te donne accès à des millions de données sur toutes les compétitions. Prédis l'imprévisible grâce à
          des statistiques détaillées sur les joueurs, les équipes et les matchs.
        </h3>
        <h3 class="lg:w-1/2 md:w-2/3 m-auto">
          Maximise tes gains et réduis tes risques.
        </h3>
        <div>
          <button class="large primary m-auto" @click="_continue">
            DEMARRE MAINTENANT
          </button>
          <button class="large accent ml-1" @click="_scrollToOffers">
            OFFRES
          </button>
        </div>
      </div>

      <div class="bloc" id="points-fort-wrapper">
        <h2 class="lg:w-2/3 md:w-2/3 m-auto">
          <span class="highlighted">L'OUTIL</span> DU PARIEUR EXIGEANT
        </h2>
        <h3 class="lg:w-1/2 md:w-2/3 m-auto">
          Docto.BET, un outil ultra personnalisable regroupant tout ce dont un parieur a besoin. Avec Docto.BET, il
          devient facile de structurer tes analyses pour améliorer tes résultats et viser des gains sur le long terme.
        </h3>
        <div style="margin-top: 64px;">
          <div class="flex m-auto points-fort-flex">
            <div class="point-fort">
              <h3 class="uppercase">Analyse personnalisée</h3>
              <p class="message">Crée tes propres perspectives pour affiner tes pronostics.</p>
            </div>
            <div class="point-fort">
              <h3 class="uppercase">Optimisation du temps</h3>
              <p class="message">Gagne du temps en automatisant les recherches de stats.</p>
            </div>
            <div class="point-fort">
              <h3 class="uppercase">Monitoring automatisé</h3>
              <p class="message">Suis l'évolution de tes stratégies et ajuste-les en fonction des résultats.</p>
            </div>

          </div>
        </div>
      </div>

      <div class="bloc lg:w-2/3 md:w-2/3 w-full m-auto">
        <div class="flex">
          <div class="w-full lg:w-2/3">
            <h2 style="text-align: left">
              <span class="highlighted">UNE BONNE STAT</span><br/> PEUT TOUT CHANGER
            </h2>
            <p>Accède à plus de 100 types de statistiques puissantes et leurs multiples variantes pour
              décortiquer chaque rencontre
              décortiquer chaque rencontre
              à venir. Que tu sois novice ou expert, notre module stats te permet de créer des perspectives
              personnalisées, te fournissant les insights précis et pertinents dont tu as besoin pour prendre des
              décisions éclairées. </p>
            <p>Ne te contente plus de parier à l'instinct : avec Docto.BET, chaque pari devient une
              stratégie gagnante.</p>
            <div>
              <button class="large youtube m-auto" @click="_openYoutube('MDMyT0NFZ8A')">
                <font-awesome-icon :icon="['fab', 'youtube']" class="mx-2"/>
              </button>
              <button class="large uppercase accent ml-1" @click="_goToStats">
                Accéder aux stats
              </button>
            </div>

          </div>
          <div class="lg:w-1/3 w-1/5 text-right">
            <font-awesome-icon icon="fa-solid fa-chart-pie" class="bloc-icon"/>
          </div>
        </div>
      </div>

      <div class="bloc lg:w-2/3 md:w-2/3 m-auto">
        <div class="flex">
          <div class="w-1/3">
            <font-awesome-icon icon="fa-solid fa-object-group" class="bloc-icon"/>
          </div>
          <div class="w-full lg:w-2/3">
            <h2 style="text-align: left">
              <span class="highlighted">Sélection</span><br/> précise
            </h2>
            <p>Avec nos sélections personnalisées, identifie en quelques secondes les rencontres qui répondent
              à des
              critères statistiques ultra-précis, parmi des centaines de matchs. Ne perds plus de temps à tout analyser
              manuellement : laisse Docto.BET faire le travail pour toi. Grâce à des possibilités infinies de filtrage
              et à la note de correspondance, tu repères instantanément les matchs les plus intéressants.
            </p>
            <p>Gagner devient une
              question de méthode, pas de chance.</p>
            <div>
              <button class="large youtube m-auto" @click="_openYoutube('bG2dHYSse4M')">
                <font-awesome-icon :icon="['fab', 'youtube']" class="mx-2"/>
              </button>
              <button class="large accent uppercase ml-1" @click="_goToGames">
                Accéder aux sélections
              </button>
            </div>

          </div>
        </div>
      </div>

      <div class="bloc lg:w-2/3 md:w-2/3 m-auto">
        <div class="flex">
          <div class="w-full lg:w-2/3">
            <h2 style="text-align: left">
              <span class="highlighted">Monitoring</span><br/> automatique
            </h2>
            <p>Avant de miser ton argent, teste tes stratégies sur le long terme en toute sécurité. Confie au
              système la
              tâche d’identifier et de placer automatiquement des paris virtuels selon tes critères ultra précis, et
              laisse-le gérer une bankroll virtuelle pour toi. Suis les performances de tes stratégies sans lever le
              petit
              doigt et ajuste-les en toute sérénité.</p>
            <p>Avec Docto.BET, tu peux explorer et affiner des dizaines de
              stratégies sans effort, maximisant ainsi tes chances de succès lorsque tu décideras de passer à
              l’action.</p>
            <button class="large youtube m-auto" @click="_openYoutube('mFS9JDsPvBM')">
              <font-awesome-icon :icon="['fab', 'youtube']" class="mx-2"/>
            </button>
          </div>
          <div class="w-1/3 text-right">
            <font-awesome-icon icon="fa-solid fa-stethoscope" class="bloc-icon"/>
          </div>
        </div>
      </div>

      <div class="bloc" id="avis-client-wrapper">
        <div class="lg:w-2/3 md:w-2/3 m-auto mb-5" style="text-align: center">
          <div class="mb-5">
            <font-awesome-icon icon="fa-solid fa-star" style="font-size: 32pt; color:#6DFFC2;" class="mx-5"/>
            <font-awesome-icon icon="fa-solid fa-star" style="font-size: 32pt; color:#6DFFC2;" class="mx-5"/>
            <font-awesome-icon icon="fa-solid fa-star" style="font-size: 32pt; color:#6DFFC2;" class="mx-5"/>
          </div>
          <h1 style="text-align: center; margin-bottom: 32px; margin-top: 32px">
            AVIS <span class="highlighted">CLIENT</span>
          </h1>
        </div>
        <div style="margin-top: 64px">
          <div class="flex m-auto avis-client-flex">
            <div class="avis-client" v-for="(avis, index) in _randomAvis">
              <div class="flex align-middle">
                <div class="avatar" :style="_avisColor(index)">{{ _firstLetter(avis) }}</div>
                <div class="name">{{ avis.name }}</div>
              </div>
              <p class="message">{{ avis.message }}</p>
            </div>

          </div>
        </div>
      </div>
      <div class="bloc" id="offers-wrapper">
        <div class="lg:w-2/3 md:w-2/3 m-auto mb-5" style="text-align: center">
          <h1 style="text-align: center; margin-bottom: 32px;">
            OFFRES <span class="highlighted">IMBATTABLES</span>
          </h1>

          <h3 style="text-align: center; margin-top: 24px">
            Docto.BET propose le meilleur rapport qualité-prix du marché. Découvre nos offres et choisis celle qui
            te convient le mieux.
          </h3>
        </div>
        <div style="margin-top: 64px">
          <div class="flex lg:w-2/3 m-auto offers-flex">
            <div class="offer NONE ">
              <h2>DEBUTANT</h2>
              <h4 class="highlighted">Gratuit</h4>
              <p>Crée un compte et profite de toutes les fonctionnalités de l'outil. Aucune limite de
                temps.</p>
              <ul>
                <li>Accès limité aux données Football</li>
                <li>13 ligues analysables</li>
                <li>Bonus de parrainage à 5%</li>
              </ul>
            </div>

            <div class="offer PRO">
              <h2>PRO</h2>
              <h4 class="highlighted">7€/an</h4>
              <ul>
                <li>Accès illimités aux données d'une trentaine de compétition dont les plus grands
                  championnats
                  Européens.
                </li>
                <li>Accès aux autres sports</li>
                <li>Bonus de parrainage à 10%</li>
                <li>Bonus sur les parrainages de vos filleuls de 5%</li>
                <li>Paiement en une fois. Pas de renouvellement automatique.</li>
              </ul>
            </div>
          </div>
          <div class="flex lg:w-1/2 m-auto mt-5">
            <div class="offer ULTIMATE w-full">
              <h2>ULTIMATE</h2>
              <h4 class="highlighted">20€/an</h4>
              <ul>
                <li>Accès illimités à 90 compétitions Football</li>
                <li>Accès au profilage de cote.</li>
                <li>Pas de limite de monitoring</li>
                <li>Bonus de parrainage à 15%</li>
                <li>Bonus sur les parrainages de vos filleuls de 10%</li>
                <li>Paiement en une fois. Pas de renouvellement automatique.</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div class="sticky-bar" @click="_signWithGoogle">
        JOUER COMPORTE DES RISQUES : ENDETTEMENT, ISOLEMENT, DÉPENDANCE. POUR ÊTRE AIDÉ, APPELEZ LE 09 74 75 13 13
      </div>

    </div>
  </div>
</template>

<style scoped lang="less">
@import "@/assets/styles/base.less";

@keyframes appear {
  from {
    opacity: 0;
    scale: 0.5;
  }
  to {
    opacity: 1;
    scale: 1;
  }
}

.bloc {
  animation: appear linear;
  animation-timeline: view();
  animation-range: entry 0;
}

@name-height: 36px;
.main-welcome-pane {
  margin: auto;
  text-align: center;
  overflow-x: hidden;
  overflow-y: scroll;
  max-height: calc(100vh - @toolbar-regular);
  scrollbar-width: none;
  padding-bottom: 100px;

  .highlighted {
    background: linear-gradient(90deg, #FF501E 0%, #FFA528 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    color: transparent;
    font-weight: bolder;
  }

  h1 {
    font-family: 'PoppinsBold', serif;
    font-size: 4.6875rem !important;
    color: #fff !important;
    margin-bottom: 24px !important;
    font-weight: bolder;
    text-transform: uppercase;
  }

  h2 {
    font-family: 'PoppinsBold', serif;
    font-size: 44px !important;
    color: #fff !important;
    margin-bottom: 24px !important;
    font-weight: bolder;
    text-transform: uppercase;
  }

  h3 {
    font-family: 'PoppinsRegular', serif;
    font-size: 1.25rem !important;
    font-weight: 100 !important;
    color: #fff !important;
    margin-bottom: 16px !important;
  }

  h4 {
    font-family: 'PoppinsBold', serif;
    font-weight: bolder;
    text-transform: uppercase;
  }

  .bloc {
    max-width: 1024px;
    margin: auto;
    text-align: left;
    padding: 64px 10px;
  }

  p, ul, li {
    font-size: 1.125rem; /* Slightly larger font size */
    line-height: 1.14;
    color: #fff; /* Dark gray text color */
    font-family: 'Arial', serif; /* Font family */
    text-align: left;
  }

  ul {
    list-style-type: circle;
    padding: 0;

    li {
      margin-left: 16px;
    }
  }

  .bloc-icon {
    margin-top: 100px;
    color: #fff;
    font-size: 128px;
  }

  #points-fort-wrapper {
    .points-fort-flex {
      gap: @padding-regular; /* Adjust the gap as needed */
      display: flex;
      flex-wrap: wrap;
      padding: @padding-regular;
    }

    .point-fort {
      text-align: center;
      flex: 1 1 300px; /* permet aux div de croître et se contracter, avec une base de 350px */
      box-sizing: border-box;
      width: 300px;
      max-width: calc(100%);
      padding: 16px;
      border-radius: @radius-regular;
      background: rgb(255, 255, 255);
      background: linear-gradient(135deg, rgba(255, 255, 255, 0.10) 0%, rgba(255, 255, 255, 0.08) 20%, rgba(115, 115, 115, 0.05) 33%, rgba(115, 115, 115, 0.10) 100%);

      p {
        text-align: center;
        font-size: 11pt;
      }
    }
  }

  #avis-client-wrapper {
    .avis-client-flex {
      gap: @padding-regular; /* Adjust the gap as needed */
      display: flex;
      flex-wrap: wrap;
      padding: @padding-regular;
    }

    .avis-client {
      flex: 1 1 300px; /* permet aux div de croître et se contracter, avec une base de 350px */
      box-sizing: border-box;
      width: 300px;
      max-width: calc(100%);
      padding: 16px;
      border-radius: @radius-regular;
      background: rgb(255, 255, 255);
      background: linear-gradient(135deg, rgba(255, 255, 255, 0.10) 0%, rgba(255, 255, 255, 0.08) 20%, rgba(115, 115, 115, 0.05) 33%, rgba(115, 115, 115, 0.10) 100%);

      .name {
        color: white;
        font-weight: bold;
        font-size: 14pt;
        line-height: @name-height;
      }

      .message {
        font-style: italic;
        margin: 0 !important;
        padding-left: @name-height + @padding-regular;
      }

      .avatar {
        font-weight: bold;
        background: @primary-color;
        border-radius: 50%;
        width: @name-height;
        height: @name-height;
        display: inline-block;
        line-height: @name-height;
        margin-right: @padding-regular;
        text-align: center;
      }
    }

  }

  #offers-wrapper {

    .offers-flex {
      gap: @padding-regular; /* Adjust the gap as needed */
      display: flex;
      flex-wrap: wrap;
      padding: @padding-regular;
    }

    .offer {
      flex: 1 1 300px; /* permet aux div de croître et se contracter, avec une base de 350px */
      box-sizing: border-box;
      width: 300px;
      max-width: calc(100%);
      padding: 16px;
      border-radius: @radius-regular;

      &.NONE {
        background: rgb(255, 255, 255);
        background: linear-gradient(135deg, rgba(255, 255, 255, 0.10) 0%, rgba(255, 255, 255, 0.08) 20%, rgba(115, 115, 115, 0.05) 33%, rgba(115, 115, 115, 0.10) 100%);
      }

      &.PRO {
        background: @accent-color;
        background: linear-gradient(135deg, fade(@accent-color, 30%) 0%, fade(@accent-color, 20%) 20%, fade(@accent-color, 15%) 33%);
      }

      &.ULTIMATE {
        background: rgb(236, 156, 44);
        background: linear-gradient(135deg, #f97f2866 0%, #f97f2866 33%, #fea32866 66%, #fea32866 100%);
      }
    }
  }


  .sticky-bar {
    justify-content: center;
    cursor: pointer;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    transition: transform 200ms, opacity 200ms;
    height: 2.8125rem;
    padding: 0 0.9375rem 0 0;
    z-index: 20;
    background: rgba(0, 0, 0, 0.7);
    font-family: "gt_americaextended_black", "Arial Black", "Arial Bold", sans-serif;
    font-size: 0.875rem;
    text-transform: uppercase;
    line-height: 1.1;
    color: white;
    overflow: hidden;
    text-decoration: none;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media (max-width: 599px) {
  .main-welcome-pane {
    h1 {
      font-size: 3.125rem !important;
    }

    h2 {
      font-size: 2.5rem !important;
    }

    h3 {
      font-size: 1rem !important;
    }

    .bloc {
      padding: 32px 10px;
    }

    p, ul, li {
      font-size: 1rem; /* Slightly larger font size */
    }

    .bloc-icon {
      font-size: 64px;
    }

    .offers {
      .offer {
        padding: 16px;
        border-radius: @radius-regular;

        &.NONE {
          margin-right: 0;
        }

        &.PRO {
          margin-left: 0;
        }
      }
    }
  }
}

</style>
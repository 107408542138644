<script>
import {gameStore} from "@/stores/Game.store";
import {liveStore} from "@/stores/Live.store";

export default {
  name: "GameLiveScore",
  props: {
    uid: {
      required: true
    },
    location: {
      type: String,
      default: 'BOTH'
    }
  },

  computed: {
    _game() {
      if(!this.uid) return null;
      return gameStore().get(this.uid);
    },
    _getData(){
      if (!this._game) return null;
      return liveStore().getBySportAndUID(this._game.sport, this._game.uid);
    },
    _getHomeScore() {
      if(!this._getData) return ' ';
      let score = this._getData.homeScore;
      if(null === score || score === undefined) {
        score = ' ';
      }
      return score
    },
    _getAwayScore() {
      if(!this._getData) return ' ';
      let score = this._getData.awayScore;
      if(null === score || score === undefined) {
        score = ' ';
      }
      return score
    }
  },
}
</script>

<template>
  <div class="game-score" :class="{'game-score' : true, 'live': _getData !== null}">
    <span :class="{'blink': _getData !== null}">{{ _getHomeScore }}/{{ _getAwayScore }}</span>
  </div>
</template>

<style scoped lang="less">
@import "@/assets/styles/variables.less";

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.game-score {
  line-height: 24px;
  padding: 0 @padding-small;
  color: @text-color;
  font-size: 9pt;
  font-family: 'SourceCodePro', serif !important;
  border-radius: @radius-regular;
  font-weight: bold;
  background: #00000033;
  min-width: 30px;

  &.live {
    background: darkred;
    color: white;
  }

  .blink {
    animation: blink 1s linear infinite;
  }
}
</style>

<script>

import {selectionStore} from "@/stores/Selection.store";

export default {
  name: "SelectionMatch",
  components: {},
  props: {
    uid: {
      required: true
    },
    note: {
      default: 0.0
    },
    positive: {
      type: Boolean,
      default: true
    }
  },

  computed: {
    _selection() {
      return selectionStore().get(this.uid)
    },
    _value() {
      if(!this.note) return '0.0'
      return this.note.toFixed(1)
    },
    _selectionLabel() {
      if(!this._selection) return '?'
      return this._selection.name
    },
    _buildCssClasses() {
      return {
        'selection-match flex': true,
        'positive': this.positive,
        'negative': !this.positive
      }
    }
  }
}
</script>

<template>
  <div :class="_buildCssClasses" v-if="_selection">
    <div class="value pl-2">
      {{_value}}
    </div>
    <div class="label ml-2 text-left py-1" style="line-height: normal">
      {{ _selectionLabel }}
    </div>
  </div>
  <div v-else>
    Loading...
  </div>
</template>

<style scoped lang="less">
@import "@/assets/styles/base.less";

.selection-match {
  color: white;
  font-size: @font-size-small;
  min-height: 24px;
  line-height: 24px;
  align-items: center;

  &:hover {
    background: @hover-color;
  }

  &.positive {
    background: @positive-color;
    background: linear-gradient(131deg, fade(@positive-color, 60%) 0%, fade(@positive-color, 30%) 20%, rgba(0,0,0,0) 33%);

    .value{

      font-weight: bold;
    }

  }

  &.negative {
    background: @negative-color;
    background: linear-gradient(131deg, fade(@negative-color, 60%) 0%, fade(@negative-color, 30%) 20%, rgba(0,0,0,0) 33%);

  }


  .value {
    font-weight: bold;
    text-align: right;
    min-width: 32px;
    display: flex;
    align-items: center;
  }
}
</style>
<script>
import SelectionPane from "@/components/routes/selection/panes/selection/SelectionPane.vue";
import AnalysePane from "@/components/routes/selection/panes/analyse/AnalysePane.vue";
import MainHeader from "@/components/common/MainHeader.vue";
import WelcomePane from "@/components/routes/selection/panes/welcome/WelcomePane.vue";
import {contextStore} from "@/stores/Context.store";
import {message} from 'ant-design-vue';
import {selectionPaneStore} from "@/components/routes/selection/panes/selection/SelectionPane.store";

export default {
  name: "SelectionRoute",
  components: {
    MainHeader, AnalysePane, SelectionPane,
    WelcomePane
  },
  computed: {
    _isMobile() {
      return contextStore().isMobileDevice()
    },
    _selectedGameUID() {
      return contextStore().getSelectedGameUID()
    }
  },
  beforeMount() {
    if (contextStore().shouldReload()) {
      window.location.href = window.location.origin
    }

    if (!contextStore().isInitialized()) {
      this.$router.push({name: 'init'})
    }
  },
  mounted() {
    if (!contextStore().isInitialized()) return;
    if (!contextStore().isAuthenticated()) return;

    // On met a jour le nom de la route courante
    contextStore().setRoute('main')
  }
}
</script>

<template>
  <div :class="{'mobile': _isMobile}">
    <main-header/>
    <div class="flex">
      <selection-pane/>
      <analyse-pane v-if="!_isMobile && _selectedGameUID"/>
      <welcome-pane v-else-if="!_isMobile"/>
    </div>

  </div>
</template>

<style scoped lang="less">

</style>
<script>
import SelectionOperation from "@/components/routes/selection/panes/selection/condition/SelectionOperation.vue";
import SelectionConditionCriteria from "@/components/routes/selection/modals/criteria/SelectionConditionCriteria.vue";
import ConditionFormLeagueResult from "@/components/routes/selection/modals/condition_forms/ConditionFormLeagueResult.vue";
import ConditionFormLeaguePoints from "@/components/routes/selection/modals/condition_forms/ConditionFormLeaguePoints.vue";
import ConditionFormLeagueScore from "@/components/routes/selection/modals/condition_forms/ConditionFormLeagueScore.vue";
import ConditionFormTeamScore from "@/components/routes/selection/modals/condition_forms/ConditionFormTeamScore.vue";
import ConditionFormTeamResult from "@/components/routes/selection/modals/condition_forms/ConditionFormTeamResult.vue";
import ConditionFormTeamPoints from "@/components/routes/selection/modals/condition_forms/ConditionFormTeamPoints.vue";

// Stores
import {selectionStore} from "@/stores/Selection.store";
import {statStore} from "@/stores/Statistic.store";
import {offerStore} from "@/stores/Offer.store";
import {modalStore} from "@/stores/Modal.store";
import {contextStore} from "@/stores/Context.store";
import {bookmakerStore} from "@/stores/Bookmaker.store";

export default {
  name: "EditSelectionConditionModal",
  components: {
    SelectionConditionCriteria,
    SelectionOperation,

    ConditionFormTeamResult,
    ConditionFormTeamScore,
    ConditionFormTeamPoints,
    ConditionFormLeagueResult,
    ConditionFormLeaguePoints,
    ConditionFormLeagueScore
  },

  mounted() {

  },

  data() {
    return {
      editedOperand: null,
    }
  },
  computed: {
    _isMobile() {
      return contextStore().isMobileDevice()
    },
    _payload() {
      let visibleModal = modalStore().getVisibleModal;
      if (!visibleModal) return false;
      return visibleModal.payload;
    },
    _isVisible() {
      let visibleModal = modalStore().getVisibleModal;
      if (!visibleModal) return false;
      return visibleModal.uid === 'editSelectionConditionModal';
    },
    _sport() {
      if (!this._payload) return null
      return this._payload.sport
    },
    _selectionUID() {
      if (!this._payload) return null
      return this._payload.selectionUid
    },
    _selection() {
      if (!this._selectionUID) return null;
      return selectionStore().get(this._selectionUID)
    },
    _selectionType() {
      if (!this._selection) return null;
      return this._selection.type
    },
    _selectionTypeLabel() {
      if (!this._selectionType) return "";
      if (this._selectionType === 'GAME') return "Rencontre"
      if (this._selectionType === 'PLAYER') return "Joueur"
    },
    _targetTypes() {
      if (!this._selectionType) return [];
      switch (this._selectionType) {
        case 'GAME':
          return [
            {label: 'Statistique', uid: 'STATISTIC_DEFINITION'},
            {label: 'Côte', uid: 'OFFER_FAMILY_CHOICE'},
            {label: 'Sélection', uid: 'SELECTION'},
            {label: 'Profilage', uid: 'ODD_PROFILING'},
          ]
        case 'PLAYER':
          return [
            {label: 'Statistique', uid: 'STATISTIC_DEFINITION'},
            {label: 'Sélection', uid: 'SELECTION'},
          ]
      }
    },
    _operand() {
      if (!this._payload) return null
      return this._payload.operand
    },
    _statisticDefinitions() {
      return statStore().getDefinitions(this._sport)
    },
    _statisticDefinitionsOptions() {
      if (!this._selectionType) return [];
      const results = [...this._statisticDefinitions]
      switch (this._selectionType) {
        case 'GAME':
          return results.filter(sdef => sdef.scope !== 'PLAYER').map(sdef => {
            return {label: sdef.name, value: sdef.fixedUID}
          })
        case 'PLAYER':
          // Mettre les stats PLAYER en premier
          results.sort((a, b) => {
            if (a.scope === 'PLAYER' && b.scope !== 'PLAYER') return -1;
            if (a.scope !== 'PLAYER' && b.scope === 'PLAYER') return 1;
            return 0;
          })
          return results.map(sdef => {
            return {label: sdef.name, value: sdef.fixedUID}
          })
      }
    },
    _offerFamiliesDefinitionsOptions() {
      const results = []
      let sportDefs = offerStore().getOfferFamilyDefinitionsBySport(this._sport);
      sportDefs.items.forEach(ofdef => {
        ofdef.choices.forEach(choice => {
          let label = offerStore().getOfferFamilyChoiceLabel(choice, "", false);
          results.push({label: label, value: choice.fixedUID})
        })
      })
      return results
    },
    _oddProfilingEventOptions() {
      const results = []
      let sportDefs = offerStore().getOfferFamilyDefinitionsBySport(this._sport);
      sportDefs.items.forEach(ofdef => {
        if (ofdef.oddProfiling === true) {
          ofdef.choices.forEach(choice => {
            let label = offerStore().getOfferFamilyChoiceLabel(choice, "", false);
            results.push({label: label, value: choice.fixedUID})
          })
        }
      })
      return results
    },
    _oddProfilingEventArguments() {

      let sportDefs = offerStore().getOfferFamilyDefinitionsBySport(this._sport)
      let currentFamily = null
      sportDefs.items.forEach(ofdef => {
        let isCurrent = false
        ofdef.choices.forEach(choice => {
          if (choice.fixedUID === this.editedOperand.targetFixedUID) {
            isCurrent = true
          }
        })
        if (isCurrent) {
          currentFamily = ofdef
        }
      })

      if (!currentFamily) return []
      if (!currentFamily.arguments) return []
      if (!currentFamily.arguments[this._sport]) return []
      // remove null value from the list
      const values = currentFamily.arguments[this._sport];

      if (values.length === 0) {
        this.editedOperand.argument = null
        return []
      }

      if (!this.editedOperand.argument) {
        this.editedOperand.argument = values[0]
      }

      return values.map(arg => {
        return {label: arg, value: arg}
      })
    },
    _currentSelectionUID() {
      if (!this._payload) return null;
      if (!this._payload.selectionUid) return null;
      return this._payload.selectionUid
    },
    _hasFullLicence() {
      return contextStore().hasFullLicence()
    },
    _canAddSelectionCondition() {
      // On doit vérifier que la selection n'est pas déjà utilisée dans une condition d'une autre selection
      let userSelections = selectionStore().getUserSelections(['GAME', 'PLAYER']);
      let selections = [];
      for (let i = 0; i < userSelections.length; i++) {
        let us = userSelections[i];
        if (us.uid && us.uid === this._currentSelectionUID) {
          continue;
        }
        const allSelectionOperands = selectionStore().getAllOperands(us.operation)
        const hasSelectionOperand = allSelectionOperands.find(o => o.targetType === 'SELECTION' && o.targetFixedUID === this._currentSelectionUID)
        if (hasSelectionOperand) {
          selections.push(us);
        }
      }
      return selections
    },
    _selectionsOptions() {
      const results = []
      let userSelections = selectionStore().getUserSelections(['GAME']);
      userSelections.map(s => {
        // On ne doit proposer que les sélections :
        // - Qui n'utilise pas de selection eux meme
        const isCurrentSelection = s.uid === this._currentSelectionUID;
        const sOperands = selectionStore().getAllOperands(s.operation)
        const hasSelectionOperand = sOperands.find(o => o.targetType === 'SELECTION')
        if (!isCurrentSelection && !hasSelectionOperand) {
          results.push({label: s.name, value: s.uid})
        }
      })
      return results
    },
    _offerFamiliesDefinitionArguments() {

      let sportDefs = offerStore().getOfferFamilyDefinitionsBySport(this._sport)
      let currentFamily = null
      sportDefs.items.forEach(ofdef => {
        let isCurrent = false
        ofdef.choices.forEach(choice => {
          if (choice.fixedUID === this.editedOperand.targetFixedUID) {
            isCurrent = true
          }
        })
        if (isCurrent) {
          currentFamily = ofdef
        }
      })

      if (!currentFamily) return []
      if (!currentFamily.arguments) return []
      if (!currentFamily.arguments[this._sport]) return []
      // remove null value from the list
      const values = currentFamily.arguments[this._sport];

      if (values.length === 0) {
        this.editedOperand.argument = null
        return []
      }

      if (!this.editedOperand.argument) {
        this.editedOperand.argument = values[0]
      }

      return values.map(arg => {
        return {label: arg, value: arg}
      })
    },
    _offerFamiliesDefinitionArgumentsComparedTo() {

      let sportDefs = offerStore().getOfferFamilyDefinitionsBySport(this._sport)
      let currentFamily = null
      sportDefs.items.forEach(ofdef => {
        let isCurrent = false
        ofdef.choices.forEach(choice => {
          if (choice.fixedUID === this.editedOperand.condition.comparedToFixedUID) {
            isCurrent = true
          }
        })
        if (isCurrent) {
          currentFamily = ofdef
        }
      })

      if (!currentFamily) return []
      if (!currentFamily.arguments) return []
      if (!currentFamily.arguments[this._sport]) return []
      // remove null value from the list
      const values = currentFamily.arguments[this._sport];
      console.log(currentFamily, values)
      if (values.length === 0) {
        this.editedOperand.condition.comparedToArgument = null
        return []
      }

      if (!this.editedOperand.condition.comparedToArgument) {
        this.editedOperand.condition.comparedToArgument = values[0]
      }

      return values.map(arg => {
        return {label: arg, value: arg}
      })
    },
    _selectedStatDefinition() {
      if (!this.editedOperand) return null
      return statStore().getDefinitionByFixedUID(this.editedOperand.targetFixedUID)
    },
    _selectedStatDefinitionEchantillonMin(){
      if(!this._selectedStatDefinition) return 0
      let echantillonMin = this._selectedStatDefinition.echantillonMin;
      if(echantillonMin == null) return 0
      return echantillonMin
    },
    _selectedStatDefinitionEchantillonMax(){
      if(!this._selectedStatDefinition) return 0
      let echantillonMax = this._selectedStatDefinition.echantillonMax;
      if(!echantillonMax == null) return 100
      return echantillonMax
    },
    _playerChoices() {
      if (!this.editedOperand) return []
      if (!this._selectedStatDefinition) return []
      switch (this._selectedStatDefinition.scope) {
        case 'TEAM':
        case 'LEAGUE':
          return []
        case 'PLAYER':
          return [
            {label: 'Joueur à dom.', value: 'HOME'},
            {label: 'Joueur à ext.', value: 'AWAY'}
          ]
      }

    },
    _whoChoices() {
      if (!this.editedOperand) return []
      if (!this._selectedStatDefinition) return []

      let results = []
      switch (this._selectedStatDefinition.scope) {
        case 'TEAM':
          results = [
            {label: 'Un des clubs', value: 'ANY'},
            {label: 'Club à dom.', value: 'HOME'},
            {label: 'Club à ext.', value: 'AWAY'}
          ]
          break
        case 'PLAYER':
        case 'LEAGUE':
        default:
          results = []
          break
      }

      // Keep only valid choices if defined in sdef
      if (this._selectedStatDefinition.whoChoices) {
        results = results.filter(item => this._selectedStatDefinition.whoChoices.includes(item.value))
      }

      return results
    },
    _againstChoices() {
      if (!this.editedOperand) return []
      if (!this._selectedStatDefinition) return []

      let results = []
      switch (this._selectedStatDefinition.scope) {
        case 'TEAM':
          results = [
            {label: 'Tout club confondu.', value: 'ANY'},
            {label: 'Adversaire du jour', value: 'CURRENT'},
          ]
          break
        case 'PLAYER':
          results = [
            {label: 'Tout club confondu.', value: 'ANY'}
          ]
          break
        default:
          results = []
          break
      }

      // Keep only valid choices if defined in sdef
      if (this._selectedStatDefinition.againstChoices) {
        results = results.filter(item => this._selectedStatDefinition.againstChoices.includes(item.value))
      }

      return results
    },
    _periodChoices() {
      if (!this.editedOperand) return []
      if (!this._selectedStatDefinition) return []

      let scopeChoices = []
      switch (this._selectedStatDefinition.scope) {
        case 'TEAM':
          scopeChoices = [
            {label: 'Saison', value: 'CURRENT_SEASON'},
            {label: '5 dern. match', value: 'LAST_5_GAMES'},
            {label: '10 dern. match', value: 'LAST_10_GAMES'},
            {label: 'All time', value: 'ALL_TIME'},
          ]
          break;
        case 'LEAGUE':
        case 'PLAYER':
          scopeChoices = [
            {label: 'Saison', value: 'CURRENT_SEASON'},
            {label: 'All time', value: 'ALL_TIME'},
          ]
          break;
        default:
          scopeChoices = []
          break;
      }

      // On va restreindre les choix en fonction du sport
      switch (this._sport) {
        case 'TENNIS':
          scopeChoices = scopeChoices.filter(c => c.value !== 'CURRENT_SEASON')
          break
        default:
          // Nothing to do
          break
      }

      // Keep only valid choices if defined in sdef
      if (this._selectedStatDefinition.periodChoices) {
        scopeChoices = scopeChoices.filter(item => this._selectedStatDefinition.periodChoices.includes(item.value))
      }

      return scopeChoices
    },
    _locationChoices() {
      if (!this.editedOperand) return []
      if (!this._selectedStatDefinition) return []

      let results = []
      switch (this._selectedStatDefinition.scope) {
        case 'TEAM':
        case 'LEAGUE':
          let currentLabel = 'Position courante'
          if (this.editedOperand.who === 'HOME') {
            currentLabel = 'A domicile'
          } else if (this.editedOperand.who === 'AWAY') {
            currentLabel = 'A l\'extérieur'
          }
          results = [
            {label: 'Peu importe', value: 'ANY'},
            {label: currentLabel, value: 'CURRENT'},
          ]
          break
        case 'PLAYER':
          results = [
            {label: 'Peu importe', value: 'ANY'}
          ]
          break
        default:
          results = []
          break
      }


      // Keep only valid choices if defined in sdef
      if (this._selectedStatDefinition.locationChoices) {
        results = results.filter(item => this._selectedStatDefinition.locationChoices.includes(item.value))
      }

      return results
    },
    _leagueChoices() {
      if (!this.editedOperand) return []
      if (!this._selectedStatDefinition) return []

      let results = []
      switch (this._selectedStatDefinition.scope) {
        case 'TEAM':
        case 'LEAGUE':
          results = [
            {label: 'Compét. courante', value: 'CURRENT'},
            {label: 'Toute compét. confondue', value: 'ANY'},
          ]
          break
        case 'PLAYER':
          results = [
            {label: 'Compét. courante', value: 'CURRENT'},
          ]
          break
        default:
          results = []
          break
      }

      // Keep only valid choices if defined in sdef
      if (this._selectedStatDefinition.leagueChoices) {
        results = results.filter(item => this._selectedStatDefinition.leagueChoices.includes(item.value))
      }

      return results
    },

    _oddOperatorChoices() {
      return [
        {label: 'sup. à', value: 'UPPER'},
        {label: 'inf. à', value: 'LOWER'},
      ]
    },

    _oddCompareEcarts() {
      const values = []
      for (let value = 0; value <= 0.50; value += 0.05) {
        let _val = parseFloat(value.toFixed(2))
        values.push({label: (value * 100.0).toFixed(0) + "% ou+", value: _val})
      }
      return values
    },

    _oddBookmakerChoices() {
      const results = [
        {label: 'chez un bookmaker', value: null}
      ]
      const bookmakers = bookmakerStore().getAll
      bookmakers.forEach(b => {
        results.push({label: 'chez ' + b.name, value: b.uid})
      })
      return results
    },

    _frequencyChoices() {
      if (!this.editedOperand) return []
      if (!this._selectedStatDefinition) return []
      switch (this._selectedStatDefinition.type) {
        case 'RAW':
          return [
            {label: 'plus de', value: 'UPPER'},
            {label: 'moins de', value: 'LOWER'},
          ]
        default:
          return [
            {label: 'plus de', value: 'UPPER'},
            {label: 'moins de', value: 'LOWER'},
          ]
      }

    },

    _frequencyValues() {
      const values = []
      if (!this.editedOperand) return []
      if (!this._selectedStatDefinition) return []
      if (this._selectedStatDefinition.type === 'RAW') {
        return []
      }
      // add all values between 0 and 1 by 0.025 step
      for (let value = 0; value <= 1.01; value += 0.025) {
        let _val = parseFloat(value.toFixed(2))
        values.push({label: (value * 100.0).toFixed(1) + "%", value: _val})
      }
      return values
    },

    _oddProfilingFrequencyValues() {
      const values = []
      for (let value = 0; value <= 1.01; value += 0.025) {
        let _val = parseFloat(value.toFixed(2))
        values.push({label: (value * 100.0).toFixed(1) + "%", value: _val})
      }
      return values
    },

    _label() {
      if (!this.editedOperand) return '?'
      if (!this._selectedStatDefinition) return '??'
      return statStore().getOperandLabel(this._selectedStatDefinition, this.editedOperand)
    },

    _valuableIndicatorEnabled() {
      if (!this.editedOperand) return false
      if (!this.editedOperand.condition) return false
      return this.editedOperand.condition.valuableIndicator !== null && this.editedOperand.condition.valuableIndicator !== undefined
    },

    _shouldShowLocationCritere() {
      if (this._sport === 'TENNIS') {
        return false
      }
      return true
    }
  },
  methods: {
    _onConfirm() {
      selectionStore().updateOperand(this.editedOperand)
      this._onCancel()
    },
    _onCancel() {
      modalStore().closeModal()
    },

    _filterOptions(input, option) {
      return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },

    _onWhoChoice(who) {
      this.editedOperand.who = who.value;
    },
    _onPlayerChoice(player) {
      this.editedOperand.player = player.value;
    },
    _onAgainstChoice(against) {
      this.editedOperand.against = against.value;
    },
    _onPeriodChoice(period) {
      this.editedOperand.period = period.value;
    },
    _onLocationChoice(location) {
      this.editedOperand.location = location.value;
    },
    _onLeagueChoice(league) {
      this.editedOperand.league = league.value;
    },

    _isTargetTypeSelected(targetType) {
      if (!this.editedOperand) return false;
      if (!this.editedOperand.targetType) return false;
      return this.editedOperand.targetType === targetType
    },

    _setTargetType(targetType) {

      if (!this.editedOperand || this.editedOperand.targetType === targetType.uid) return

      this.editedOperand.targetType = targetType.uid
      this.editedOperand.targetFixedUID = null
      this.editedOperand.argument = null
      this.editedOperand.who = null
      this.editedOperand.against = null
      this.editedOperand.period = null
      this.editedOperand.location = null
      this.editedOperand.league = null
      this.editedOperand.player = null
      this.editedOperand.condition = {
        operator: null,
        value: null
      }
    },

    _switchValuableIndicatorEnabled() {
      if (this.editedOperand.condition.valuableIndicator === null) {
        this.editedOperand.condition.valuableIndicator = 0.0
      } else {
        this.editedOperand.condition.valuableIndicator = null
      }
    },

    _onStatDefChange() {
      this.editedOperand.matchingScoreNegativeImpact = this._selectedStatDefinition.matchingScoreNegativeImpact
    }

  },
  watch: {
    _operand: {
      handler: function (val) {
        if (!val) return
        // deep copy of the operand
        this.editedOperand = JSON.parse(JSON.stringify(val))
      },
    },
    _selectedStatDefinition: {
      handler: function (val) {
        if (!val) return

        let firstOperatorValue = (this._frequencyChoices && this._frequencyChoices[0]) ? this._frequencyChoices[0].value : null;
        if (!this.editedOperand.condition.operator) {
          this.editedOperand.condition.operator = firstOperatorValue
        } else {
          if (!this._frequencyChoices.find(c => c.value === this.editedOperand.condition.operator)) {
            this.editedOperand.condition.operator = firstOperatorValue
          }
        }

        if (typeof this.editedOperand.condition.value === 'undefined' || this.editedOperand.condition.value === null) {
          if (this.editedOperand.targetType === 'OFFER_FAMILY_CHOICE') {
            this.editedOperand.condition.value = 2.0
          } else {
            this.editedOperand.condition.value = 0.70
          }
        }

        if (typeof this.editedOperand.condition.valueAbsolute === 'undefined' || this.editedOperand.condition.valueAbsolute === null) {
          this.editedOperand.condition.valueAbsolute = val.type === 'SERIE' ? 3 : 0
        }

        if (this.editedOperand.targetType === 'OFFER_FAMILY_CHOICE') {
          return  // no need to go further
        }

        // On va alimenter les valeurs who, against, period, location, league
        let firstPlayerValue = (this._playerChoices && this._playerChoices[0]) ? this._playerChoices[0].value : null;
        if (!this.editedOperand.player) {
          this.editedOperand.player = firstPlayerValue
        } else {
          if (!this._playerChoices.find(c => c.value === this.editedOperand.player)) {
            this.editedOperand.player = firstPlayerValue
          }
        }

        // On va alimenter les valeurs who, against, period, location, league
        let firstWhoValue = (this._whoChoices && this._whoChoices[0]) ? this._whoChoices[0].value : null;
        if (!this.editedOperand.who) {
          this.editedOperand.who = firstWhoValue
        } else {
          if (!this._whoChoices.find(c => c.value === this.editedOperand.who)) {
            this.editedOperand.who = firstWhoValue
          }
        }

        let firstAgainstValue = (this._againstChoices && this._againstChoices[0]) ? this._againstChoices[0].value : null;
        if (!this.editedOperand.against) {
          this.editedOperand.against = firstAgainstValue
        } else {
          if (!this._againstChoices.find(c => c.value === this.editedOperand.against)) {
            this.editedOperand.against = firstAgainstValue
          }
        }

        let firstPeriodValue = (this._periodChoices && this._periodChoices[0]) ? this._periodChoices[0].value : null;
        if (!this.editedOperand.period) {
          this.editedOperand.period = firstPeriodValue
        } else {
          if (this._periodChoices && !this._periodChoices.find(c => c.value === this.editedOperand.period)) {
            this.editedOperand.period = firstPeriodValue
          }
        }

        let firstLocationValue = (this._locationChoices && this._locationChoices[0]) ? this._locationChoices[0].value : null;
        if (!this.editedOperand.location) {
          this.editedOperand.location = firstLocationValue
        } else {
          if (!this._locationChoices.find(c => c.value === this.editedOperand.location)) {
            this.editedOperand.location = firstLocationValue
          }
        }

        let firstLeagueValue = (this._leagueChoices && this._leagueChoices[0]) ? this._leagueChoices[0].value : null;
        if (!this.editedOperand.league) {
          this.editedOperand.league = firstLeagueValue
        } else {
          if (!this._leagueChoices.find(c => c.value === this.editedOperand.league)) {
            this.editedOperand.league = firstLeagueValue
          }
        }

        // check echalillon min and max
        if (this.editedOperand.condition.valueAbsolute < this._selectedStatDefinitionEchantillonMin) {
          this.editedOperand.condition.valueAbsolute = this._selectedStatDefinitionEchantillonMin
        }
        if (this.editedOperand.condition.valueAbsolute > this._selectedStatDefinitionEchantillonMax) {
          this.editedOperand.condition.valueAbsolute = this._selectedStatDefinitionEchantillonMax
        }
      },
    }
  }

}
</script>

<template>
  <AModal v-if="_isVisible" :open="true" :width="650" @cancel="_onCancel" :class="{'mobile': _isMobile}">
    <header>
      Définition condition {{ _selectionTypeLabel }}
    </header>

    <div class="content">
      <div class="flex">
        <button :class="{'tab no-radius': true, 'selected': _isTargetTypeSelected(tt.uid)}"
                @click="_setTargetType(tt)" v-for="tt in _targetTypes">
          {{ tt.label }}
        </button>
      </div>
      <hr class="mb-1"/>
      <div v-if="editedOperand">
        <div v-if="editedOperand.targetType === 'STATISTIC_DEFINITION'">
          <div class="selection-condition-criteria">
            <a-select
                v-model:value="editedOperand.targetFixedUID"
                show-search
                :filter-option="_filterOptions"
                placeholder="Choix statistique sur laquelle baser la condition."
                :options="_statisticDefinitionsOptions"
                @change="_onStatDefChange"/>

          </div>

          <fieldset class="m-1 no-side-border" v-if="_selectedStatDefinition">
            <legend v-if="_selectedStatDefinition.type === 'SERIE'">Probabilité de fin de série</legend>
            <legend v-else-if="_frequencyValues.length === 0">Valeur</legend>
            <legend v-else>Fréquence de l'événement</legend>
            <div class="selection-condition-criteria">
              <a-select
                  class=""
                  v-model:value="editedOperand.condition.operator"
                  :options="_frequencyChoices"/>
              <a-select
                  class="ml-2"
                  v-model:value="editedOperand.condition.value"
                  :options="_frequencyValues" v-if="_frequencyValues.length > 0"/>
              <a-input-number
                  class="ml-2"
                  v-model:value="editedOperand.condition.value"
                  :min="0" :max="1000" :step="0.1" v-if="_frequencyValues.length === 0"/>
            </div>
          </fieldset>


          <fieldset class="m-1  no-side-border" v-if="_selectedStatDefinition && _selectedStatDefinitionEchantillonMin < _selectedStatDefinitionEchantillonMax">
            <legend>Taille minimale de l'échantillon</legend>
            <div class="selection-condition-criteria">
              <a-input-number
                  v-model:value="editedOperand.condition.valueAbsolute"
                  :min="_selectedStatDefinitionEchantillonMin" :max="_selectedStatDefinitionEchantillonMax" :step="1"/>
            </div>
          </fieldset>

          <fieldset class="m-1 pb-1 no-side-border"
                    v-if="_selectedStatDefinition && _selectedStatDefinition.scope === 'TEAM'">
            <legend>Club</legend>
            <div class="selection-condition-criteria" v-if="_whoChoices.length > 0">
              <div class="flex-grow">
                <button v-for="who in _whoChoices" :key="who.value"
                        :class="{'primary selected': editedOperand.who === who.value}"
                        @click="_onWhoChoice(who)">
                  {{ who.label }}
                </button>
              </div>
            </div>
          </fieldset>

          <fieldset class="m-1 pb-1 no-side-border"
                    v-if="_selectedStatDefinition && _selectedStatDefinition.scope === 'PLAYER'">
            <legend>Joueur</legend>
            <div class="selection-condition-criteria" v-if="_playerChoices.length > 0">
              <div class="flex-grow">
                <button v-for="player in _playerChoices" :key="player.value"
                        :class="{'primary selected': editedOperand.player === player.value}"
                        @click="_onPlayerChoice(player)">
                  {{ player.label }}
                </button>
              </div>
            </div>
          </fieldset>
          <fieldset class="m-1 pb-1 no-side-border" v-if="_selectedStatDefinition">
            <legend>Adversaire</legend>
            <div class="selection-condition-criteria" v-if="_againstChoices.length > 0">
              <div class="flex-grow">
                <button v-for="against in _againstChoices" :key="against"
                        :class="{'primary selected': editedOperand.against === against.value}"
                        @click="_onAgainstChoice(against)">
                  {{ against.label }}
                </button>
              </div>
            </div>
          </fieldset>
          <fieldset class="m-1 pb-1 no-side-border" v-if="_selectedStatDefinition">
            <legend>Période</legend>
            <div class="selection-condition-criteria" v-if="_periodChoices && _periodChoices.length > 0">
              <div class="flex-grow">
                <button v-for="period in _periodChoices" :key="period"
                        :class="{'primary selected': editedOperand.period === period.value}"
                        @click="_onPeriodChoice(period)">{{
                    period.label
                  }}
                </button>
              </div>
            </div>
          </fieldset>
          <fieldset class="m-1 pb-1 no-side-border" v-if="_shouldShowLocationCritere && _selectedStatDefinition && _locationChoices.length > 0">
            <legend>Position (dom/ext)</legend>
            <div class="selection-condition-criteria">
              <div class="flex-grow">
                <button v-for="location in _locationChoices" :key="location"
                        :class="{'primary selected': editedOperand.location === location.value}"
                        @click="_onLocationChoice(location)">
                  {{ location.label }}
                </button>
              </div>
            </div>
          </fieldset>
          <fieldset class="m-1 pb-1 no-side-border" v-if="_selectedStatDefinition">
            <legend>Compétition</legend>
            <div class="selection-condition-criteria" v-if="_leagueChoices.length > 0">
              <div class="flex-grow">
                <button v-for="league in _leagueChoices" :key="league"
                        :class="{'primary selected': editedOperand.league === league.value}"
                        @click="_onLeagueChoice(league)">{{ league.label }}
                </button>
              </div>
            </div>
          </fieldset>
          <a-alert v-if="_selectedStatDefinition" :message="_label" class="m-2 px-4 py-3"
                   style="font-size: 14px !important;"/>
        </div>

        <div v-else-if="editedOperand.targetType === 'OFFER_FAMILY_CHOICE'">
          <fieldset class="m-1 no-border">
            <legend>Côte cible</legend>
            <div class="selection-condition-criteria">
              <a-select
                  v-model:value="editedOperand.targetFixedUID"
                  show-search
                  :filter-option="_filterOptions"
                  placeholder="Choix de la côte sur laquelle baser la condition."
                  :options="_offerFamiliesDefinitionsOptions"
                  @change="editedOperand.matchingScoreNegativeImpact = 'MEDIUM'"/>
              <a-select
                  class="ml-2"
                  v-model:value="editedOperand.argument"
                  show-search
                  :options="_offerFamiliesDefinitionArguments"
                  v-if="_offerFamiliesDefinitionArguments.length > 0"/>
              <a-select
                  class="ml-2"
                  v-model:value="editedOperand.condition.bookmakerUID"
                  :options="_oddBookmakerChoices"/>
            </div>
          </fieldset>
          <a-alert message="Si la côte n'a pas pu être récupérer pour ce bookmaker, le match pourrait ne pas remonter dans les résultats."
                   type="warning" show-icon class="m-2" v-if="editedOperand.condition.bookmakerUID"/>
          <fieldset class="m-1 no-border">
            <legend>Valeur absolue de la côte</legend>
            <div class="selection-condition-criteria">
              <a-select
                  v-model:value="editedOperand.condition.operator"
                  :options="_oddOperatorChoices"/>
              <a-input-number
                  class="ml-2"
                  v-model:value="editedOperand.condition.value"
                  :min="0" :max="1000" :step="0.1"/>

            </div>
          </fieldset>
          <fieldset class="m-1 no-border">
            <legend>Valeur relative de la côte</legend>
            <div class="selection-condition-criteria">
              <a-select
                  v-model:value="editedOperand.condition.comparedToOperator"
                  :options="_oddOperatorChoices"/>
              <a-select
                  class="ml-2"
                  v-model:value="editedOperand.condition.comparedToFixedUID"
                  show-search
                  :filter-option="_filterOptions"
                  placeholder="Côte à comparer."
                  :options="_offerFamiliesDefinitionsOptions"/>
              <a-select
                  class="ml-2"
                  v-model:value="editedOperand.condition.comparedToArgument"
                  show-search
                  :options="_offerFamiliesDefinitionArgumentsComparedTo"
                  v-if="_offerFamiliesDefinitionArgumentsComparedTo.length > 0"/>
              <a-select
                  class="ml-2"
                  v-model:value="editedOperand.condition.comparedToEcart"
                  placeholder="Ecart"
                  :options="_oddCompareEcarts"/>
            </div>
          </fieldset>

          <hr class="my-1"/>
          <fieldset class="m-1 no-border">
            <legend>Indicateur de value minimal</legend>
            <div class="selection-condition-criteria flex">
              <button :class="{'info mr-2': true, 'primary selected': _valuableIndicatorEnabled}"
                      style="height: 34px; width: 100px" @click="_switchValuableIndicatorEnabled">
                {{ _valuableIndicatorEnabled ? 'Activé' : 'Désactivé' }}
              </button>
              <a-input-number class="mr-2 text-center" style="width: 48px;" v-if="_valuableIndicatorEnabled"
                              v-model:value="editedOperand.condition.valuableIndicator"
                              :min="0" :max="1" :step="0.01"/>

              <div class="slider-container flex-grow" v-if="_valuableIndicatorEnabled">
                <a-slider
                    v-model:value="editedOperand.condition.valuableIndicator"
                    :min="-1" :max="1" :step="0.01"/>
              </div>

            </div>
            <a-alert
                message="Valeur entre -1.0 et 1.0 représentant l'interet d'une côte en rapport avec sa probabilité."
                type="info" show-icon class="m-1"/>
          </fieldset>
        </div>

        <div v-else-if="editedOperand.targetType === 'ODD_PROFILING'">
          <div v-if="_hasFullLicence">
            <fieldset class="m-1 no-border">
              <legend>Evènement</legend>
              <div class="selection-condition-criteria">
                <a-select
                    v-model:value="editedOperand.targetFixedUID"
                    show-search
                    :filter-option="_filterOptions"
                    placeholder="Evènement de profilage sur lequel baser la condition."
                    :options="_oddProfilingEventOptions"
                    @change="editedOperand.matchingScoreNegativeImpact = 'MEDIUM'"/>
                <a-select
                    class="ml-2"
                    v-model:value="editedOperand.argument"
                    show-search
                    :options="_oddProfilingEventArguments"
                    v-if="_oddProfilingEventArguments.length > 0"/>
              </div>
            </fieldset>

            <fieldset class="m-1 no-border">
              <legend>Taux de réalisation de l'évènement</legend>
              <div class="selection-condition-criteria">
                <a-select
                    v-model:value="editedOperand.condition.operator"
                    :options="_oddOperatorChoices"/>
                <a-select
                    class="ml-2"
                    v-model:value="editedOperand.condition.value"
                    :options="_oddProfilingFrequencyValues"/>
              </div>
            </fieldset>
            <fieldset class="m-1 no-side-border">
              <legend>Nombre minimal de match similaire</legend>
              <div class="selection-condition-criteria">
                <a-input-number
                    v-model:value="editedOperand.condition.valueAbsolute"
                    :min="0" :max="200" :step="1"/>
              </div>
            </fieldset>
            <a-alert
                message="Le profilage se fait sur les 3 marchés combinés (1X2, Under/Over et BTTS) et avec une marge de 2.5%"
                type="info" show-icon class="m-2"/>
          </div>
          <div v-else>
            <a-alert message="Il faut une licence ULTIMATE pour utiliser la sélection par profilage de cote."
                     type="info" show-icon class="m-1"/>
          </div>


        </div>

        <div v-else-if="editedOperand.targetType === 'SELECTION'">
          <div v-if="_hasFullLicence && _canAddSelectionCondition.length === 0">
            <fieldset class="m-1 no-border">
              <legend>Selection cible</legend>
              <div class="selection-condition-criteria">
                <a-select
                    v-model:value="editedOperand.targetFixedUID"
                    show-search
                    :filter-option="_filterOptions"
                    placeholder="Choix de selection dans laquelle la rencontre doit apparaitre."
                    :options="_selectionsOptions"
                    @change="editedOperand.matchingScoreNegativeImpact = 'MEDIUM'"/>
              </div>
            </fieldset>
            <a-alert
                message="Pour des raisons de performance, les conditions sur les sélections ne peuvent se faire que sur un niveau."
                type="info" show-icon class="m-2"/>
          </div>
          <div v-else>
            <a-alert v-if="!_hasFullLicence"
                     message="Il faut une licence ULTIMATE pour utiliser cette fonctionnalité."
                     type="info" show-icon class="m-2"/>
            <a-alert v-if="_canAddSelectionCondition.length > 0"
                     message="La selection est déjà utilisée dans une condition des selections ci-dessous. Pour des raisons de sécurité et de performance, tu ne peux pas ajouter condition de type sélection ici."
                     type="error" show-icon class="m-2"/>
            <a-alert v-for="s in _canAddSelectionCondition" :message="s.name" class="m-2"/>
          </div>
        </div>
      </div>
    </div>

    <template #footer>
      <div class="my-2 mx-1 flex">
        <button class="" @click="_onCancel">Annuler</button>
        <div class="flex-grow"/>
        <button class="primary" @click="_onConfirm">Appliquer condition</button>
      </div>
    </template>

  </AModal>
</template>

<style lang="less">
@import "@/assets/styles/variables.less";


.selection-condition-criteria {
  line-height: @toolbar-small !important;
  color: @text-color !important;
  margin: 0 !important;
  padding: @padding-small @padding-small !important;
  display: flex;

  .ant-select {
    font-family: 'SourceCodePro', serif !important;
    font-size: @font-size-button !important;
    flex-grow: 1;

    .ant-select-selector {
      color: @text-color !important;
      background-color: @background-color-light !important;
    }

    .ant-select-arrow {
      color: @text-color !important;
    }
  }

  .ant-select-selection-item {
    font-size: @font-size-button !important;
    color: @text-color !important;
  }

  .slider-container {
    background-color: @background-color-light !important;
    margin: 0 !important;

    border-radius: @radius-regular !important;

  }

}
</style>